import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import { useParams } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import Grid from '@material-ui/core/Grid'
import { Typography } from '@material-ui/core'
import { apiClientInstance } from '../../../apiClient'
import Header from '../../../components/Header'
import { getClientModelPerSiteApi, getClientModelsApi, getClientSitesApi } from './apiConstants'
import ClientsMaturityModelsDisplayCard from './ClientsMaturityModelsDisplayCard'

const helpText = `
## Überblick Reifegradmodelle 

Für die ausgewählte Betriebsstätte wird der aktuelle Status für jedes Reifegradmodell im Überblick dargestellt.

Das Diagramm auf der Kachel zeigt Ziel und erreichten Status für jedes Prozessdomäne im Vergleich sowie den prozentualen Anteil der Fragen 
für die der Zielreifegrad erfüllt wird.


- Klick auf die Kachel führt zum letzten Status
- Neu: neuen Status erfassen (nicht möglich falls das Reifegradmoidell gesperrt ist)
- Historie: alle erfassten Status 
- Ziele: Ziel-Reifegrad und Gewichtung der Fragen definieren

`
const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  grid: {
    display: 'flex',
    justifyContent: 'flex-start'
  }
}))

const ClientsMaturityModelsDisplay = ({ className }) => {
  const { clientId, siteId } = useParams()
  const classes = useStyles()

  const [models, setModels] = useState([])
  const [siteName, setSiteName] = useState('')
  const { enqueueSnackbar } = useSnackbar()

  const fetchData = () => {
    if (siteId) {
      apiClientInstance.get(getClientModelPerSiteApi(clientId, siteId), { size: 50 })
        .then((response) => {
          setModels(response.data.content)
        })
        .catch((error) => {
          enqueueSnackbar(`Fehler beim Laden: ${error}`, {
            variant: 'error',
          })
        })
      apiClientInstance.get(getClientSitesApi(clientId) + siteId)
        .then((response) => {
          setSiteName(response.data.name)
        })
        .catch((error) => {
          enqueueSnackbar(`Fehler beim Laden: ${error}`, {
            variant: 'error',
          })
        })
    } else {
      apiClientInstance.get(getClientModelsApi(clientId))
        .then((response) => {
          setModels(response.data.content)
        })
        .catch((error) => {
          enqueueSnackbar(`Fehler beim Laden: ${error}`, {
            variant: 'error',
          })
        })
    }
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteId])

  return (
    <div className={clsx(classes.root, className)}>
      <Header title={`Reifegradmodelle für ${siteName}`} helpText={helpText} helpTitle="Überblick Reifergradmodelle"/>
      <Grid container spacing={2} className={classes.grid}>
        {models && models.map((entry) => (
          <Grid item sm={12} md={6} key={entry.modelId}>
            <ClientsMaturityModelsDisplayCard entry={entry} siteId={siteId}/>
          </Grid>
        ))}
        {!(models.length > 0) && (
        <Typography align="center">
          Keine Modelle für diesen Mandanten gefunden
        </Typography>
        )}
      </Grid>
    </div>
  )
}

ClientsMaturityModelsDisplay.propTypes = {
  className: PropTypes.string,
}

export default ClientsMaturityModelsDisplay

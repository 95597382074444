import React, { useEffect, useState } from 'react'
import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'
import EditForm from '../../../components/crud/EditForm'
import { apiClientInstance } from '../../../apiClient'
import editSchema from './questionSchema.json'
import uiSchema from './questionsUiSchema.json'
import Header from '../../../components/Header'
import { getModelQuestionApi } from './apiConstants'

const EditQuestion = () => {

  // id is not part of new object
  const schema = { ...editSchema }
  delete schema.properties.id
  const { id, questionId } = useParams()
  const [data, setData] = useState({})

  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const save = (formData) => {
    const url = `${getModelQuestionApi(id)}${questionId}`
    apiClientInstance.put(url, formData)
      .then(() => {
        enqueueSnackbar('Frage bearbeitet', {
          variant: 'success',
        })
        navigate('../../../')
      })
      .catch((error) => {
        enqueueSnackbar(`Fehler beim Bearbeiten: ${error}`, {
          variant: 'error',
        })
      })
  }

  const fetchData = () => {
    apiClientInstance.get(`${getModelQuestionApi(id)}${questionId}`)
      .then((response) => {
        setData({
          modelId: id,
          id: questionId,
          title: response.data.title,
          level0: response.data.level0,
          level1: response.data.level1,
          level2: response.data.level2,
          level3: response.data.level3,
          level4: response.data.level4,
          level5: response.data.level5,
        })
      }).catch((error) => {
        enqueueSnackbar(`Fehler beim Laden: ${error}`, {
          variant: 'error',
        })
      })
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <Header title="Frage bearbeiten"/>
      <EditForm
        schema={schema}
        uiSchema={uiSchema}
        formData={data}
        onSubmit={save}
      />
    </div>
  )
}
EditQuestion.propTypes = {
}

export default EditQuestion

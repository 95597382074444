import React from 'react'
import { Container, Grid, makeStyles } from '@material-ui/core'
import Page from 'src/components/Page'
import { Route, Routes } from 'react-router-dom'
import AuthGuard from '../../../components/AuthGuard'
import ClientsList from './ClientsList'
import NewClient from './NewClient'
import NewSite from './NewSite'
import DetailedClient from './DetailedClient'
import EditClient from './EditClient'
import EditSite from './EditSite'
import ClientsMaturityModels from './ClientsMaturityModels'
import ClientsUsers from './ClientsUsers'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}))

const Clients = () => {
  const classes = useStyles()

  return (
    <Page
      className={classes.root}
      title="Mandanten">
      <AuthGuard requiredRole={['admin', 'consultant']}>
        <Container maxWidth={false}>
          <Grid
            container>
            <Grid item xs={12}>
              <Routes>
                <Route name="clientList" path="/" element={<ClientsList/>}/>
                <Route path="/?page=:page" element={<ClientsList/>}/>
                <Route path=":id" element={<DetailedClient/>}/>
                <Route path=":id/maturityModels" element={<ClientsMaturityModels/>}/>
                <Route path=":id/users" element={<ClientsUsers/>}/>
                <Route path=":id/:siteId" element={<EditSite/>}/>
                <Route path=":id/edit" element={<EditClient/>}/>
                <Route path="new" element={<NewClient/>}/>
                <Route path=":id/sites/new" element={<NewSite/>}/>
              </Routes>
            </Grid>
          </Grid>
        </Container>
      </AuthGuard>
    </Page>
  )
}

export default Clients

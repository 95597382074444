import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import Paper from '@material-ui/core/Paper'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import { IconButton, Typography } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import AddIcon from '@material-ui/icons/Add'
import { Link } from 'react-router-dom'
import moment from 'moment'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { apiClientInstance } from '../../../apiClient'
import SubmitFab from '../../../components/SubmitFab'
import Header from '../../../components/Header'
import TableFooter from '../../../components/TableFooter'
import DialogDelete from '../../../components/DialogDelete'
import { baseMaturityModelApi, getOneModelApi } from './apiConstants'

const helpText = `
## Reifegradmodelle (RGM)

Hier werden die Reifegradmodelle verwaltet.
Die Liste zeigt alle verfügbaren Modelle.

### Name
Der Name des Reifegradmodells

### Zuletzt bearbeitet
Datum der letzten Änderung

### Freigegeben
Ist ein RGM freigegeben, so kann es von Mandanten verwendet und Status dazu erfasst werden.
Wird ein bereits genutztes RGM gesperrt, so können Mandanten keinen neuen Status mehr erfassen. Bestehende Status können noch angezeigt werden.

### Version
Die Version des RGM. 
`

const useStyles = makeStyles((theme) => ({
  root: {},
  fab: {
    position: 'fixed',
    bottom: theme.spacing(5),
    right: theme.spacing(5),
  },
  tableCell: {
    borderBottomColor: theme.palette.divider,
  },
  placeholder: {
    height: theme.spacing(10)
  }
}))

const MaturityModelList = ({ className }) => {
  const classes = useStyles();

  const [models, setModels] = useState([])
  const [allItemsCount, setAllItemsCount] = useState(0)
  const [totalPages, setTotalPages] = useState(0)
  const { enqueueSnackbar } = useSnackbar()
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [toDeleteEntry, setToDeleteEntry] = React.useState({});

  const fetchData = (params) => {
    apiClientInstance.get(baseMaturityModelApi, params)
      .then((response) => {
        setModels(response.data.content)
        setAllItemsCount(response.data.totalElements)
        setTotalPages(response.data.totalPages)
      })
      .catch((error) => {
        enqueueSnackbar(`Fehler beim Laden: ${error}`, {
          variant: 'error',
        })

      })

  }

  const handleDialogOpen = (entry) => {
    setToDeleteEntry(entry)
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleDelete = () => {
    const url = getOneModelApi(toDeleteEntry.id)
    apiClientInstance.delete(url)
      .then(() => {
        handleDialogClose()
        fetchData()
        enqueueSnackbar(` ${toDeleteEntry.name} Erfolgreich gelöscht!`, {
          variant: 'success',
        })
      }).catch((error) => {
        enqueueSnackbar(`Fehler beim Löschen: ${error}`, {
          variant: 'error',
        })
        handleDialogClose()
      })
  }

  const booleanToString = (bool) => {
    if (bool) {
      return 'freigegeben'
    }
    return 'gesperrt'

  }

  return (
    <div className={clsx(classes.root, className)}>
      <Header
        title="Reifegradmodelle"
        helpTitle="Reifegradmodelle"
        helpText={helpText}/>
      <DialogDelete
        title={toDeleteEntry.name}
        handleClose={handleDialogClose}
        handleDelete={handleDelete}
        dialogText="Das Reifegradmodell wird von allen Nutzern entfernt und steht dann nicht mehr zur Verfügung"
        open={dialogOpen}/>
      <Paper>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableCell}>
                  <h3>
                    Name
                  </h3>
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <h3>
                    Zuletzt bearbeitet
                  </h3>
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <h3>
                    Freigegeben
                  </h3>
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <h3>
                    Version
                  </h3>
                </TableCell>
                <TableCell className={classes.tableCell}/>
              </TableRow>
            </TableHead>
            <TableBody>
              {models && models.map((entry) => (
                <TableRow key={entry.id}>
                  <TableCell className={classes.tableCell}>
                    <Typography>
                      {entry.name}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Typography>
                      {moment(entry.lastUpdateTime)
                        .format('DD.MM.YY HH:mm')}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Typography>
                      {booleanToString(entry.released)}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Typography>
                      {entry.version}
                    </Typography>
                  </TableCell>
                  <TableCell align="right" className={classes.tableCell}>
                    <IconButton onClick={() => handleDialogOpen(entry)}>
                      <DeleteOutlineIcon/>
                    </IconButton>
                    <IconButton component={Link} to={`${entry.id}/`}>
                      <ArrowForwardIcon/>
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              {!(models.length > 0) && (
                <TableRow>
                  <TableCell width="100%">
                    <Typography align="center">
                      Keine Reifegradmodelle gefunden
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TableFooter
          allItemsCount={allItemsCount}
          fetchData={(params) => fetchData(params)}
          totalPages={totalPages}
        />
        <SubmitFab show component={Link} to="new" text="Hinzufügen">
          <AddIcon/>
        </SubmitFab>
      </Paper>
      <div className={classes.placeholder}/>
    </div>
  )
}

MaturityModelList.propTypes = {
  className: PropTypes.string,
}

export default MaturityModelList

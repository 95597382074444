import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { Grid, makeStyles, Typography } from '@material-ui/core'
import QuickHelpButton from './QuickHelpButton'

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(1),
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: theme.spacing(2)
  }
}));

const Header = ({ className, title, children, helpTitle, helpText, ...rest }) => {
  const classes = useStyles();

  return (
    <Grid
      container
      spacing={3}
      justify="space-between"
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Grid item>
        <Typography
          variant="h3"
          color="textPrimary"
        >
          {title}
        </Typography>
      </Grid>
      <div className={classes.item}>
        <Grid item>
          {children}
        </Grid>
        {helpText && helpTitle
        && (
        <QuickHelpButton helpText={helpText} helpTitle={helpTitle}/>
        )}
      </div>
    </Grid>
  );
};

Header.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.element,
  helpTitle: PropTypes.string,
  helpText: PropTypes.string,
};

export default Header;

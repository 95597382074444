export const baseClientApi = 'client/'
export const baseClientsApi = 'clients/'

export function getOneClientApi (id) {
  return `${baseClientApi}${id}`
}

export function getClientSitesApi (id) {
  return `${baseClientsApi}${id}/sites/`
}

export function getClientItSystemsApi (id) {
  return `${baseClientsApi}${id}/itsystem`
}

export function getClientModelsApi (id) {
  return `${baseClientApi}${id}/maturitymodels/`
}

export function getClientModelPerSiteApi (clientId, siteId) {
  return `${baseClientApi}${clientId}/sites/${siteId}/maturitymodels/`
}

export function getClientModelGoals (clientId, siteId, modelId) {
  return `${baseClientApi}${clientId}/sites/${siteId}/maturitymodels/${modelId}`
}

export function getClientModelGoalsPerQuestion (clientId, siteId, modelId, questionId) {
  return `${baseClientApi}${clientId}/sites/${siteId}/maturitymodels/${modelId}/goals/${questionId}`
}

export function getClientModelStatusApi (clientId, siteId, modelId) {
  return `${baseClientApi}${clientId}/sites/${siteId}/maturitymodels/${modelId}/status/`
}

export function getClientModelStatusAnswerApi (clientId, siteId, modelId, statusId, questionId) {
  return `${baseClientApi}${clientId}/sites/${siteId}/maturitymodels/${modelId}/status/${statusId}/answer/${questionId}`
}

export function getSubDomainItSystemsApi (clientId, siteId, modelId, statusId, subDomainId) {
  return `${baseClientApi}${clientId}/sites/${siteId}/maturitymodels/${modelId}/status/${statusId}/itsystem/${subDomainId}`
}

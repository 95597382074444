import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { colors, Divider, IconButton, Typography } from '@material-ui/core'
import Popover from '@material-ui/core/Popover'
import Box from '@material-ui/core/Box'
import InputIcon from '@material-ui/icons/Input'
import SignOutButton from './SignOutButton'

const useStyles = makeStyles((theme) => ({
  popover: {
    width: 320
  },
  text: {
    paddingBottom: theme.spacing(2)
  },
  divider: {
    marginLeft: -theme.spacing(2),
    marginRight: -theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  button: {
    color: colors.blueGrey[800],
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium,
  },
  icon: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
  }
}))

const AccountPopUp = ({ email, children }) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (

    <div>
      <IconButton onClick={handleClick}>
        {children}
      </IconButton>
      <Popover
        classes={{ paper: classes.popover }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        transition="true">
        <Box p={2}>
          <Typography className={classes.text}>
            {email}
          </Typography>
          <Divider className={classes.divider}/>
          <SignOutButton className={classes.button}>
            <div className={classes.icon}><InputIcon size={20}/></div>
            Abmelden
          </SignOutButton>
        </Box>
      </Popover>
    </div>
  )
}

AccountPopUp.propTypes = {
  email: PropTypes.string,
  children: PropTypes.element
}

export default AccountPopUp

export const baseClientsApi = 'clients/'
export const baseClientApi = 'client/'

export function getClientSitesApi (id) {
  return `${baseClientsApi}${id}/sites/`
}

export function getClientStatusByModelApi (id, modelId) {
  return `${baseClientApi}${id}/maturitymodels/${modelId}/status`
}

export function getClientSettingsApi (id) {
  return `${baseClientApi}${id}/settings/`
}

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import { Grid, Typography } from '@material-ui/core'
import { useParams } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import Header from '../../../components/Header'
import LayoutCard from '../../../components/LayoutCard'
import { calculateAchievedPercentage } from '../maturityModels/statusCalculations'
import { apiClientInstance } from '../../../apiClient'
import { getClientModelsApi } from '../maturityModels/apiConstants'
import { getClientSettingsApi, getClientStatusByModelApi } from './apiConstants'
import palette from '../../../theme/palette'

const useStyles = makeStyles(() => ({
  root: {
  },
  grid: {
    display: 'flex',
    justifyContent: 'center'
  }
}))

const DashboardPage = ({ className }) => {
  const { clientId } = useParams()
  const classes = useStyles()
  // Muss array von chartData werden. chartData ist für nur ein Model aktuell
  const [chartData, setChartData] = useState([])
  const [data, setData] = useState([])
  const { enqueueSnackbar } = useSnackbar()

  const calculatePercentagePerSite = (siteData) => {
    if (siteData.status && siteData.goalsPerQuestion) {
      const { answers } = siteData.status
      const goals = siteData.goalsPerQuestion

      return calculateAchievedPercentage(answers, goals)
    }
    return 0

  }

  const buildChartData = (modelData, modelName, modelId, modelVersion, settings) => {
    const tempData = {
      data: [],
      keys: ['status'],
      index: ['site'],
      name: modelName,
      id: modelId,
      version: modelVersion,
    }
    modelData.sites.forEach((site) => {
      let name
      if (settings?.siteLabelField === 'shortName' && site.shortName) {
        name = site.shortName
      } else {
        name = site.name.substring(0, 10)
        if (site.name.length > 10) {
          name += '...'
        }
      }
      const status = !Number.isNaN(Math.round(calculatePercentagePerSite(site) * 100) / 100)
        ? Math.round(calculatePercentagePerSite(site) * 100) / 100 : 0
      let color
      switch (true) {
        case status < 20:
          color = palette.graph.first
          break;
        case status < 40:
          color = palette.graph.second
          break;
        case status < 60:
          color = palette.graph.third
          break;
        case status < 80:
          color = palette.graph.fourth
          break;
        default:
          color = palette.graph.fifth
          break
      }
      tempData.data.push({
        id: site.id,
        site: name,
        status,
        color
      })
    })
    const x = chartData
    x.push(tempData)
    setChartData(x)
  }

  const compare = (a, b) => {
    const x = a.name.toLowerCase()
    const y = b.name.toLowerCase()
    if (x < y) {
      return -1;
    }
    if (x > y) {
      return 1;
    }
    if (x === y) {
      if (x < y) {
        return 1;
      }
      if (x > y) {
        return -1;
      }
    }
    return 0;
  }

  const fetchData = () => {
    apiClientInstance.get(getClientSettingsApi(clientId))
      .then((resp) => {
        apiClientInstance.get(getClientModelsApi(clientId), { size: 50 })
          .then((response) => {
            response.data.content.forEach((model) => {
              apiClientInstance.get(getClientStatusByModelApi(clientId, model.modelId))
                .then((r) => {
                  buildChartData(
                    r.data,
                    model.name,
                    model.modelId,
                    model.version,
                    resp.data.settings
                  )
                  if (chartData.length === response.data.content.length) {
                    setData(chartData.sort(compare))
                  }
                })
                .catch((error) => {
                  enqueueSnackbar(`Fehler beim Laden: ${error}`, {
                    variant: 'error',
                  })
                })
            })
          })
          .catch((error) => {
            enqueueSnackbar(`Fehler beim Laden: ${error}`, {
              variant: 'error',
            })
          })
      })
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={clsx(classes.root, className)}>
      <Header title="Dashboard"/>
      <Grid container spacing={2} className={classes.grid}>
        {data && data.map((entry) => (
          <Grid item lg={12} md={12} sm={12} key={entry.id}>
            <LayoutCard chartData={entry} name={`${entry.name} - ${entry.version}`}/>
          </Grid>
        ))}
        {!(data.length > 0) && (
        <Typography align="center">
          Diesem Mandanten sind keine Reifegradmodelle zugewiesen
        </Typography>
        )}
      </Grid>
    </div>
  )
}

DashboardPage.propTypes = {
  className: PropTypes.string,
}

export default DashboardPage

import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid, Typography } from '@material-ui/core'
import Page from 'src/components/Page'
import PropTypes from 'prop-types'
import NewPasswordForm from '../../components/NewPasswordForm'
import { withAuth } from '../../auth'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(15),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  content: {
    maxWidth: 535,
    minWidth: 535,
  },
}))

const NewPassword = ({ auth }) => {
  const classes = useStyles()
  const { signedIn, user, error, completeNewPassword } = auth

  const handleSubmit = (values) => {
    completeNewPassword(user, values.password)
  }

  return (
    <Grid container className={classes.root}>
      {signedIn
      && (
      <Grid item>
        <Page title="neues Passwort" className={classes.content}>
          <Typography
            color="textSecondary"
            variant="body1">
            Vergeben Sie ein neues Passwort für Ihr Nutzerkonto.
          </Typography>
          <NewPasswordForm onSubmit={handleSubmit}/>
          {error
          && (
            <Typography
              className={classes.errorText}
              color="error"
              variant="subtitle2">
              {error}
            </Typography>
          )}
        </Page>
      </Grid>
      )}
    </Grid>
  )
}

NewPassword.propTypes = {
  auth: PropTypes.any,
  completeNewPassword: PropTypes.func,
  error: PropTypes.any,
  user: PropTypes.any,
  signedIn: PropTypes.any
}

export default withAuth(NewPassword)

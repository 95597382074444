import React from 'react'
import PropTypes from 'prop-types'
import { Box, Typography } from '@material-ui/core'

// install (please make sure versions match peerDependencies)
// yarn add @nivo/core @nivo/radar
import { ResponsiveRadar } from '@nivo/radar'
import makeStyles from '@material-ui/core/styles/makeStyles'
import withStyles from '@material-ui/core/styles/withStyles'
import palette from '../../theme/palette'
// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

const useStyles = makeStyles((theme) => ({
  box: {
    marginBottom: theme.spacing(1)
  },
  percentage: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginBottom: -theme.spacing(14),
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
  typography: {
    paddingLeft: theme.spacing(1),
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column'
  },
  text: {
    alignSelf: 'flex-start'
  },
  displayArea: {
    width: '100%',
    height: '310px',
    backgroundColor: theme.palette.divider,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
}))

const TextTypography = withStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    fontSize: 70,
    marginBottom: -theme.spacing(1),
  }
}))(Typography);

// see https://nivo.rocks/radar
const RadarChart = ({ chartData, card, noPercent }) => {
  const classes = useStyles()

  const tooltipFormat = (value) => {
    return `${Number(value)
      .toLocaleString('de-DE', {
        maximumFractionDigits: 1,
      })}`
  }

  const LabelComponent = () => (
    <g transform={`translate(${0}, -20)`}>
      <text>
        {' '}
      </text>
    </g>
  )

  let chart
  if (!chartData?.data || chartData.data.length === 0) {
    chart = (
      <div className={classes.displayArea}>
        <Typography variant="h4">
          Es scheint keine Daten für dieses Modell zu geben
        </Typography>
      </div>
    )
  } else {
    chartData.data[0].color = palette.graph.second
    if (chartData.data.length > 1) {
      chartData.data[1].color = palette.graph.third
    }
    chart = (
      <ResponsiveRadar
        data={chartData.data}
        keys={chartData.keys}
        indexBy={chartData.index}
        maxValue="auto"
        margin={{ top: 70, right: 80, bottom: 40, left: 80 }}
        curve="linearClosed"
        borderWidth={2}
        borderColor={{ from: 'color' }}
        gridLevels={5}
        gridShape="circular"
        gridLabel={card || chartData.data.length > 12 ? LabelComponent : undefined}
        gridLabelOffset={36}
        enableDots
        dotSize={5}
        dotColor={{ theme: 'background' }}
        dotBorderWidth={2}
        dotBorderColor={{ from: 'color' }}
        enableDotLabel={!card}
        dotLabel={false}
        dotLabelYOffset={-12}
        colors={chartData.data.map((entry) => entry.color)}
        fillOpacity={0.25}
        blendMode="multiply"
        animate
        motionConfig="wobbly"
        isInteractive
        tooltipFormat={tooltipFormat}
        legends={[
          {
            anchor: 'bottom-right',
            direction: 'column',
            translateX: -80,
            translateY: -77,
            itemWidth: 80,
            itemHeight: 20,
            itemTextColor: '#999',
            symbolSize: 12,
            symbolShape: 'circle',
            effects: [
              {
                on: 'hover',
                style: {
                  itemTextColor: '#000',
                },
              },
            ],
          },
        ]}
    />
    )
  }
  return (
    <Box
      height={300}
      className={classes.box}
    >
      {noPercent ? <></> : (
        <div>
          {(chartData.percentage || chartData.percentage === 0) && (
          <div className={classes.percentage}>
            <div className={classes.typography}>
              <TextTypography variant="h1">{`${Math.round(chartData.percentage)} %`}</TextTypography>
              <Typography className={classes.text}>von Ihrem Ziel erreicht</Typography>
            </div>
          </div>
          )}
        </div>
      )}
      {chart}
    </Box>
  )
}

RadarChart.propTypes = {
  chartData: PropTypes.object,
  card: PropTypes.bool,
  noPercent: PropTypes.bool,
}

export default RadarChart

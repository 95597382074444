import React, { useEffect, useState } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router'
import { useSnackbar } from 'notistack'
import EditForm from '../../../components/crud/EditForm'
import { apiClientInstance } from '../../../apiClient'
import schema from './schema.json'
import uiSchema from './uiSchema.json'
import Header from '../../../components/Header'
import { getOneFieldApi } from './apiConstants'

const EditActionField = () => {
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const { id } = useParams()
  const [state, setState] = useState([])

  useEffect(() => {

    apiClientInstance.get(getOneFieldApi(id))
      .then((response) => {
        setState({ data: response.data })
      })
  }, [id])

  const save = async (formData) => {
    try {
      const response = await apiClientInstance.put(getOneFieldApi(id), formData)
      setState({ data: response.data })
      enqueueSnackbar('Gespeichert', {
        variant: 'success',
      })
      navigate(-1)
    } catch (error) {
      enqueueSnackbar(`Fehler beim Speichern: ${error}`, {
        variant: 'error',
      })
    }
  }

  return (
    <>
      <Header title="Handlungsfeld bearbeiten"/>
      {state.data && (
      <EditForm
        title="Handlungsfeld bearbeiten"
        schema={schema}
        uiSchema={uiSchema}
        formData={state.data}
        onSubmit={save}
      />
      )}
      {!state.data && <CircularProgress color="secondary"/>}
    </>
  )
}

export default EditActionField

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import Paper from '@material-ui/core/Paper'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import { IconButton, Typography } from '@material-ui/core'
import { useParams } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import AddIcon from '@material-ui/icons/Add'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import DialogContent from '@material-ui/core/DialogContent'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { apiClientInstance } from '../../../apiClient'
import DialogDelete from '../../../components/DialogDelete'
import Header from '../../../components/Header'
import SubmitFab from '../../../components/SubmitFab'
import { getOneClientApi, getOneClientModelsApi } from './apiConstants'
import { baseMaturityModelApi } from '../maturityModel/apiConstants'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(5),
    right: theme.spacing(5),
  },
  tableCell: {
    borderBottomColor: theme.palette.divider
  },
  tableFooter: {
    marginRight: theme.spacing(8),
  },
  select: {
    width: '100%'
  },
  placeholder: {
    height: theme.spacing(10)
  }
}))

const ClientsMaturityModels = ({ className }) => {
  const classes = useStyles()

  const [name, setName] = useState('')
  const [models, setModels] = useState([])
  const [allModels, setAllModels] = useState([])
  const [selectedModel, setSelectedModel] = useState({})
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const [modelDialogOpen, setModelDialogOpen] = React.useState(false);
  const [currentSelectedEntry, setCurrentSelectedEntry] = React.useState({});
  const { enqueueSnackbar } = useSnackbar()
  const { id } = useParams()

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const fetchData = () => {
    apiClientInstance.get(getOneClientApi(id))
      .then((response) => {
        setName(response.data.name)
      }).catch((error) => {
        enqueueSnackbar(`Fehler beim Laden: ${error}`, {
          variant: 'error',
        })
      })
    apiClientInstance.get(getOneClientModelsApi(id), { size: 50 })
      .then((response) => {
        setModels(response.data.content)
      }).catch((error) => {
        enqueueSnackbar(`Fehler beim Laden: ${error}`, {
          variant: 'error',
        })
      })
    apiClientInstance.get(baseMaturityModelApi, { size: 30 })
      .then((response) => {
        setAllModels(response.data.content)
        setSelectedModel(response.data.content.length > 0 ? response.data.content[0] : undefined)
      }).catch((error) => {
        enqueueSnackbar(`Fehler beim Laden: ${error}`, {
          variant: 'error',
        })
      })
  }

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
  };

  const handleDelete = () => {
    const url = getOneClientModelsApi(id)
    apiClientInstance.delete(url + currentSelectedEntry.modelId)
      .then(() => {
        handleDeleteDialogClose()
        fetchData()
        enqueueSnackbar(` ${currentSelectedEntry.name} erfolgreich gelöscht!`, {
          variant: 'success',
        })
      }).catch((error) => {
        enqueueSnackbar(`Fehler beim Löschen: ${error}`, {
          variant: 'error',
        })
        handleDeleteDialogClose()
      })
  }

  const handleDialogOpen = (entry) => {
    setCurrentSelectedEntry(entry)
    setDeleteDialogOpen(true);
  };

  const findIdInModels = (entry) => {
    return models.find((model) => model.modelId === entry.id)
  }

  const handleModelDialogOpen = () => {
    setModelDialogOpen(true);
    const newAllModels = allModels.filter((entry) => !(findIdInModels(entry)))
    setAllModels(newAllModels)
    setSelectedModel(newAllModels[0])
  };

  const handleModelDialogClose = () => {
    setModelDialogOpen(false);
  };

  const handleSelectChange = (event) => {
    setSelectedModel(event.target.value)
  }

  const handleAdd = () => {
    const url = getOneClientModelsApi(id)
    const payload = {
      clientId: id,
      modelId: selectedModel.id,
      name: selectedModel.name,
      version: selectedModel.version
    }
    apiClientInstance.post(url, payload)
      .then(() => {
        handleModelDialogClose()
        fetchData()
        enqueueSnackbar(` ${selectedModel.name} erfolgreich hinzugefügt!`, {
          variant: 'success',
        })
      }).catch((error) => {
        enqueueSnackbar(`Fehler beim Hinzufügen: ${error}`, {
          variant: 'error',
        })
        handleModelDialogClose()
      })
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={clsx(classes.root, className)}>
      <Header title={`Reifegradmodelle von ${name}`}/>
      <DialogDelete
        title={currentSelectedEntry.name}
        handleClose={handleDeleteDialogClose}
        dialogText={`Wenn das Model aus dem Mandanten ${name} gelöscht wird werden auch alle vorherigen Status gelöscht`}
        handleDelete={handleDelete}
        open={deleteDialogOpen}/>
      <Dialog
        open={modelDialogOpen}
        className={classes.dialog}
        fullScreen={fullScreen}
        maxWidth="xs"
        fullWidth>
        <DialogTitle id="model_dialog">
          Reifegradmodell hinzufügen
        </DialogTitle>
        <DialogContent>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedModel}
            onChange={handleSelectChange}
            className={classes.select}
          >
            {allModels.map((model) => (
              <MenuItem value={model} key={model.id}>{model.name}</MenuItem>
            ))}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleModelDialogClose} color="primary">
            Abbrechen
          </Button>
          <Button
            onClick={handleAdd}
            color="primary"
            autoFocus>
            Bestätigen
          </Button>
        </DialogActions>
      </Dialog>
      <Paper>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableCell} style={{ width: '20%' }}>
                  <h3>
                    Name
                  </h3>
                </TableCell>
                <TableCell className={classes.tableCell}/>
                <TableCell className={classes.tableCell} style={{ width: '13%' }}/>
              </TableRow>
            </TableHead>
            <TableBody className={classes.root}>
              {models && models.map((entry) => (
                <TableRow key={entry.modelId}>
                  <TableCell className={classes.tableCell}>
                    <Typography>
                      {entry.name}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}/>
                  <TableCell align="right" className={classes.tableCell}>
                    <IconButton onClick={() => handleDialogOpen(entry)}>
                      <DeleteOutlineIcon/>
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              {!(models.length > 0) && (
                <TableRow>
                  <TableCell width="100%">
                    <Typography align="center">
                      Keine Reifegradmodelle gefunden
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <SubmitFab show onClick={handleModelDialogOpen} text="Hinzufügen">
          <AddIcon/>
        </SubmitFab>
      </Paper>
      <div className={classes.placeholder}/>
    </div>
  )
}

ClientsMaturityModels.propTypes = {
  className: PropTypes.string,
}

export default ClientsMaturityModels

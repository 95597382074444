import React, { useEffect, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { AppBar, Hidden, IconButton, makeStyles, Toolbar, Typography } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import Logo from 'src/components/Logo'
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import moment from 'moment'
import { withAuth } from '../../auth'
import { apiClientInstance } from '../../apiClient'
import ListPopUp from '../../components/ListPopUp'
import AccountPopUp from '../../components/AccountPopUp'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  user: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: theme.spacing(1),
    color: theme.palette.icon
  },
  paper: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    marginLeft: theme.spacing(2),
    color: theme.palette.secondary.light,
  }
}))

const TopBar = ({
  className,
  onMobileNavOpen,
  title,
  ...rest
}) => {
  const classes = useStyles()
  const [items, setItems] = useState([])
  const [userName, setUserName] = useState('')

  const buildItems = (version, uptime, branch, commit, number, date) => {
    const preItems = [
      { title: 'API Version', description: version },
      { title: 'Uptime', description: `${uptime} Minuten` },
      { title: 'Branch', description: branch },
      { title: 'Commit', description: commit },
      { title: 'Buildnummer', description: number },
      { title: 'Builddatum', description: moment(date).format('DD.MM.YY HH:mm') },
    ]
    setItems(preItems)
  }

  useEffect(() => {
    if (rest.auth.user) {
      if (rest.auth.user.attributes && rest.auth.user.attributes.email) {
        setUserName(rest.auth.user.attributes.email)
      } else {
        setUserName(rest.auth.user.challengeParam.userAttributes.email)
      }
    }
  }, [rest.auth])

  useEffect(() => {
    apiClientInstance.get('', {})
      .then((response) => {
        buildItems(response.data.version,
          response.data.uptimeMinutes,
          response.data.buildBranch,
          response.data.buildCommit,
          response.data.buildNumber,
          response.data.buildDate)
      })
  }, [])

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
      elevation={3}
      color="inherit">
      <Toolbar className={classes.root}>
        <div className={classes.user}>
          <Hidden lgUp>
            <IconButton
              color="inherit"
              onClick={onMobileNavOpen}>
              <MenuIcon/>
            </IconButton>
          </Hidden>
          <Hidden smDown>
            <RouterLink to="/">
              <Logo/>
            </RouterLink>
          </Hidden>
        </div>
        {title && (
          <Typography className={classes.title} variant="h3" noWrap>{title.toLocaleUpperCase()}</Typography>
        )}
        <div className={classes.user}>
          <ListPopUp title="Informationen" items={items}>
            <InfoOutlinedIcon/>
          </ListPopUp>
          {rest.auth.signedIn && (
            <AccountPopUp email={userName}>
              <PermIdentityIcon/>
            </AccountPopUp>
          )}
        </div>
      </Toolbar>
    </AppBar>
  )
}

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func,
  title: PropTypes.string
}

export default withAuth(TopBar)

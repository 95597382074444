import React from 'react'
import { Container, makeStyles } from '@material-ui/core'
import Page from 'src/components/Page'
import { Route, Routes } from 'react-router-dom'
import AuthGuard from '../../../components/AuthGuard'
import MaturityModelList from './MaturityModelList'
import NewMaturityModel from './NewMaturityModel'
import DetailedMaturityModel from './DetailedMaturityModel'
import ImportMaturityModel from './ImportMaturityModel'
import EditMaturityModel from './EditMaturityModel'
import EditQuestion from './EditQuestion'
import EditProcessDomain from './EditProcessDomain'
import EditSubDomain from './EditSubDomain'
import AddQuestion from './AddQuestion'
import AddSubDomain from './AddSubDomain'
import AddProcessDomain from './AddProcessDomain'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}))

const MaturityModel = () => {
  const classes = useStyles()

  return (
    <Page
      className={classes.root}
      title="Reifegradmodelle">
      <AuthGuard requiredRole={['admin', 'consultant']}>
        <Container maxWidth={false}>
          <Routes>
            <Route name="maturitymodel" path="/" element={<MaturityModelList/>}/>
            <Route path="/?page=:page" element={<MaturityModelList/>}/>
            <Route path="new" element={<NewMaturityModel/>}/>
            <Route path=":id" element={<DetailedMaturityModel/>}/>
            <Route path=":id/import" element={<ImportMaturityModel/>}/>
            <Route path=":id/edit" element={<EditMaturityModel/>}/>
            <Route path=":id/edit/question/:questionId" element={<EditQuestion/>}/>
            <Route path=":id/edit/processdomain/:domainId" element={<EditProcessDomain/>}/>
            <Route path=":id/edit/subdomain/:domainId" element={<EditSubDomain/>}/>
            <Route path=":id/add/questions" element={<AddQuestion/>}/>
            <Route path=":id/add/subdomains" element={<AddSubDomain/>}/>
            <Route path=":id/add/processdomains" element={<AddProcessDomain/>}/>
          </Routes>
        </Container>
      </AuthGuard>
    </Page>
  )
}

export default MaturityModel

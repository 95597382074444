import React from 'react'
import { Navigate } from 'react-router-dom'
import MainLayout from 'src/layouts/MainLayout'
import ClientLayout from 'src/layouts/ClientLayout'
import MinimalLayout from 'src/layouts/MinimalLayout'
import UsersPage from 'src/pages/adminView/users/Users'
import FormPage from 'src/pages/adminView/form/Form'
import SettingsPage from 'src/pages/adminView/settings/Settings'
import MaturityModelPage from 'src/pages/adminView/maturityModel/MaturityModel'
import ReportPage from 'src/pages/adminView/report/Report'
import FieldsOfActionPage from 'src/pages/adminView/fieldsOfAction/FieldsOfAction'
import ClientsPage from 'src/pages/adminView/clients/Clients'
import ClientDashboard from 'src/pages/clientView/dashboard/Dashboard'
import NotFoundPage from 'src/pages/errors/NotFoundPage'
import SignInPage from 'src/pages/signIn/SignIn'
import NewPassword from './pages/NewPassword/NewPassword'
import ResetPassword from './pages/ResetPassword/ResetPassword'
import ResetPasswordConfirm from './pages/ResetPasswordConfirm/ResetPasswordConfirm'
import ClientView from './pages/clientView/clientView/ClientView'
import ClientsMaturityModels from './pages/clientView/maturityModels/ClientsMaturityModels'
import ItSystems from './pages/clientView/itSystems/ItSystems'
import ClientSettings from './pages/clientView/clientSettings/ClientSettings'

const routes = [
  {
    path: 'app/admin',
    element: <MainLayout/>,
    children: [
      { path: 'dashboard', element: <Navigate to="/app/admin/clients"/> },
      { path: 'users/*', element: <UsersPage/> },
      { path: 'maturitymodel/*', element: <MaturityModelPage/> },
      { path: 'reports', element: <ReportPage/> },
      { path: 'fieldsofaction/*', element: <FieldsOfActionPage/> },
      { path: 'clients/*', element: <ClientsPage/> },
      { path: 'settings/*', element: <SettingsPage/> },
      { path: 'clientview/*', element: <Navigate to="/app/client/clientView"/> },
      { path: 'form', element: <FormPage/> },
      { path: '*', element: <Navigate to="/not-found"/> },
    ],
  },
  {
    path: 'app/client/',
    element: <ClientLayout/>,
    children: [
      { path: 'clientview/*', element: <ClientView/> },
      { path: '*', element: <Navigate to="/not-found"/> },
    ],
  },
  {
    path: 'app/client/:clientId',
    element: <ClientLayout/>,
    children: [
      { path: 'dashboard/*', element: <ClientDashboard/> },
      { path: 'systems/*', element: <ItSystems/> },
      { path: 'clientSettings/*', element: <ClientSettings/> },
      { path: '*', element: <Navigate to="/not-found"/> },
    ],
  },
  {
    path: 'app/client/:clientId/sites/:siteId',
    element: <ClientLayout/>,
    children: [
      { path: 'dashboard/*', element: <ClientDashboard/> },
      { path: 'maturitymodel/*', element: <ClientsMaturityModels/> },
      { path: '*', element: <Navigate to="/not-found"/> },
    ],
  },
  {
    path: '/',
    element: <MinimalLayout/>,
    children: [
      { path: 'not-found', element: <NotFoundPage/> },
      { path: 'sign-in', element: <SignInPage/> },
      { path: '/new-password', element: <NewPassword/> },
      { path: '/reset-password', element: <ResetPassword/> },
      { path: '/confirm-password', element: <ResetPasswordConfirm/> },
      { path: '/', element: <Navigate to="/app/admin/dashboard"/> },
      { path: '*', element: <Navigate to="/not-found"/> },
    ],
  },
]

export default routes

/*
 * Status calculation functions, to be used in charts and views
 */

export function sumReducer (accumulator, currentValue) {
  return accumulator + currentValue
}

/**
 * group the questions to processDomain level. (flatten to processDomain)
 * @param maturityModel
 * @returns array of processDomains including "questions" array with questions of all sub domains
 */
export function groupPerProcessDomain (maturityModel) {
  return maturityModel.processDomains.map((processDomain) => {
    const questionsWithinDomain = []
    processDomain.subDomains.forEach((subDomain) => {
      subDomain.questions.forEach((question) => {
        questionsWithinDomain.push({ ...question })
      })
    })

    return {
      ...processDomain,
      questions: questionsWithinDomain,
    }
  })
}

/**
 * get all subdomains
 * @param maturityModel
 * @returns array of all subdomainds
 */
export function groupPerSubDomain (maturityModel) {
  let allSubDomains = []
  maturityModel.processDomains.forEach((processDomain) => {
    allSubDomains = allSubDomains.concat(processDomain.subDomains)
  })
  return allSubDomains
}

/**
 Result looks like this:
 const chartData = {
              index: 'domain',
              keys: ["Ziel", "Aktuell"],
              data: [
                {
                  "domain": "Verantwortung",
                  "Ziel": 5,
                  "Aktuell": 1
                }
              ]
            };
 */
export function calculateGoalRadarChartData (maturityModel) {
  const chartData = {
    index: 'domain',
    keys: ['Ziel'],
    data: [],
  }

  chartData.data = groupPerProcessDomain(maturityModel)
    .map((group) => {
      const sumGoal = group.questions
        .map((q) => q.goal * q.weight)
        .reduce(sumReducer)

      const sumWeight = group.questions
        .map((q) => q.weight)
        .reduce(sumReducer)

      const averageGoal = sumGoal / sumWeight

      return {
        domain: `${group.name} (${group.questions.length} Fragen)`,
        Ziel: averageGoal,
      }
    })
  return chartData
}

export function calculateAverageWeightedScore (questions) {

  /**
   * exclude not applicable
   * @param question
   * @returns {boolean}
   */
  function isRelevant (question) {
    return question.level !== -1
  }

  const relevantQuestions = questions
    .filter(isRelevant)

  if (relevantQuestions.length === 0) {
    return {
      level: -1,
      targetLevel: -1,
    }
  }

  const sumLevel = relevantQuestions
    .map((q) => (q?.level || 0) * q.weight)
    .reduce(sumReducer)

  const sumTargetLevel = relevantQuestions
    .map((q) => q.targetLevel * q.weight)
    .reduce(sumReducer)

  const sumWeights = relevantQuestions
    .map((q) => q.weight)
    .reduce(sumReducer)

  return {
    level: sumLevel / sumWeights,
    targetLevel: sumTargetLevel / sumWeights,
  }
}

/**
 * calculates the achieved percentage for the given status, goals and model
 */
export function calculateAchievedPercentage (answers, goals) {

  let target = 0
  let score = 0
  for (const questionId in goals) {
    const goal = goals[questionId]
    const answer = answers[questionId]
    if (answer?.level === -1) {
      // skip. is not relevant
    } else {
      if (answer) {
        score += (answer.level * goal.weight)
      }
      target += (goal.targetLevel * goal.weight)
    }

  }
  return Math.round((score / target) * 100.0)
}

export function calculateStatusRadarChartData (maturityModel, answers, goals) {

  const chartData = {
    index: 'domain',
    keys: ['Ziel', 'Status'],
    data: [],
    percentage: calculateAchievedPercentage(answers, goals),
  }

  function getStatusForQuestion (q) {
    return {
      level: answers[q.id]?.level,
      targetLevel: goals[q.id].targetLevel,
      weight: goals[q.id].weight,
    }
  }

  function getName (domainName) {
    if (domainName.length > 10) {
      return `${domainName.substring(0, 10)}...`
    }
    return domainName
  }

  // note: could also group per subdomain groupPerSubDomain(maturityModel)
  chartData.data = groupPerProcessDomain(maturityModel)
    .map((domain) => {
      const statuses = domain?.questions?.map(getStatusForQuestion)
      const numAnswered = statuses.filter((a) => typeof a.level !== 'undefined').length
      const score = calculateAverageWeightedScore(statuses)
      const name = getName(domain.name)
      return {
        domain: `${name} (${numAnswered}/${domain?.questions?.length} Fragen)`,
        Status: score.level,
        Ziel: score.targetLevel,
      }
    })
    .filter((s) => s.Ziel >= 0)
  return chartData
}

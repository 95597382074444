import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Box, Divider, Drawer, Hidden, List, makeStyles, Typography } from '@material-ui/core'
import ViewCompactIcon from '@material-ui/icons/ViewCompact';
import DashboardIcon from '@material-ui/icons/Dashboard'
import Link from '@material-ui/core/Link'
import { useParams } from 'react-router'
import ApartmentIcon from '@material-ui/icons/Apartment';
import BusinessIcon from '@material-ui/icons/Business';
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';
import SettingsIcon from '@material-ui/icons/Settings';
import { baseClientsApi, getClientSettingsApi, getClientSitesApi } from '../../../pages/clientView/dashboard/apiConstants'
import { apiClientInstance } from '../../../apiClient'
import { withAuth } from '../../../auth'
import NavItem from '../../MainLayout/NavBar/NavItem'

const itemsWithoutId = [
  {
    href: '/app/client/clientView',
    icon: BusinessIcon,
    title: 'Mandantenauswahl',
  },
]

const clientManagementItems = (id) => {

  return [
    {
      href: `/app/client/${id}/dashboard`,
      icon: DashboardIcon,
      title: 'Dashboard',
    },
    {
      href: `/app/client/${id}/systems`,
      icon: DesktopWindowsIcon,
      title: 'IT Systeme'
    },
    {
      href: `/app/client/${id}/clientSettings`,
      icon: SettingsIcon,
      title: 'Mandanteneinstellungen'
    }
  ]
}

const siteItemsWithoutId = (id, sites, settings) => {
  const tree = []
  if (sites) {
    sites.forEach((site) => {
      let title
      if (settings?.siteLabelField === 'shortName' && site.shortName) {
        title = site.shortName
      } else {
        title = site.name
      }
      tree.push({
        href: `/app/client/${id}/sites/${site.id}/maturitymodel`,
        icon: ApartmentIcon,
        title
      })
    })
  }
  return tree
}

const viewItems = [
  {
    href: '/app/admin/dashboard',
    icon: ViewCompactIcon,
    title: 'Admin-Ansicht',
  },
]

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)',
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64,
  },
  select: {
    width: '100%'
  },
  subHeader: {
    marginLeft: theme.spacing(3),
  }
}))

const NavBar = ({ onMobileClose, openMobile, auth: { hasRequiredRole } }) => {
  const classes = useStyles()
  const location = useLocation()
  const { clientId } = useParams()
  const items = clientManagementItems(clientId)
  const [site, setSite] = useState('');
  const [clientSites, setClientSites] = useState([])
  const [clientCount, setClientCount] = useState(0)
  const [settings, setSettings] = useState({})

  const fetchData = () => {
    apiClientInstance.get(getClientSettingsApi(clientId))
      .then((response) => {
        setSettings(response.data.settings)
      })
    apiClientInstance.get(getClientSitesApi(clientId))
      .then((response) => {
        setClientSites(response.data.content)
        if (site === '' && response.data.conetent) {
          setSite(response.data.content[0].id)
        }
      })
    apiClientInstance.get(baseClientsApi)
      .then((response) => {
        setClientCount(response.data.length)
      })
  }

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose()
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column">
      {clientCount > 1 && (
      <Box p={2}>
        <List>
          {itemsWithoutId.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
        </List>
      </Box>
      )}
      {clientId && (
        <div>
          <Divider/>
          <Box p={2}>
            <List>
              {items.map((item) => (
                <NavItem
                  href={item.href}
                  key={item.title}
                  title={item.title}
                  icon={item.icon}
                  />
              ))}
            </List>
          </Box>
        </div>
      )}
      {clientSites.length > 0 && clientId && (
        <div>
          <Divider/>
          <Box p={2}>
            <List>
              <Typography variant="body1">
                Betriebsstätten
              </Typography>
              {siteItemsWithoutId(clientId, clientSites, settings).map((item) => (
                <NavItem
                  href={item.href}
                  key={item.title}
                  title={item.title}
                  icon={item.icon}
            />
              ))}
            </List>
          </Box>
        </div>
      )}
      <Divider/>
      {hasRequiredRole(['admin', 'consultant']) && (
      <Box p={2}>
        <List>
          <Typography variant="body1">
            Admin
          </Typography>
          {viewItems.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
        </List>
      </Box>
      )}
      <Box flexGrow={1}/>
      <Box display="flex" pl={2} pb={1}>
        <Typography variant="body1">
          Made by
          {' '}
          <Link
            component="a"
            href="https://www.siobra.de/"
            target="_blank">
            Siobra
          </Link>
          {' '}
          . 2021
        </Typography>
      </Box>
    </Box>
  )

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary">
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent">
          {content}
        </Drawer>
      </Hidden>
    </>
  )
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
  auth: PropTypes.object
}

NavBar.defaultProps = {
  onMobileClose: () => {
  },
  openMobile: false,
}

export default withAuth(NavBar)

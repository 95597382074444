import React, { useEffect, useState } from 'react'
import { Container, makeStyles, Typography } from '@material-ui/core'
import Page from 'src/components/Page'
import Button from '@material-ui/core/Button'
import * as XLSX from 'xlsx';
import Paper from '@material-ui/core/Paper'
import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import Header from '../../../components/Header'
import { apiClientInstance } from '../../../apiClient'
import MaturityModelTree from '../../../components/MaturityModelTree'
import { getModelImportApi } from './apiConstants'

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  insidePaper: {
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  button: {
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  }
}))

export function transformData (file) {
  const content = { processDomains: [] }
  file.forEach((line) => {
    // Filter header line
    if (line[0] !== 'Nummer') {
      if (!line[1]) {
        line[1] = 'WARNUNG: Keine Prozessdomäne angegeben'
      }

      // Set process domain
      const processDomain = content.processDomains.find((domain) => domain.name === line[1])
      if (!processDomain) {
        // processDomains nicht vorhanden, wird neu angelegt
        content.processDomains.push({ name: line[1], subDomains: [], id: uuidv4() })
      }

      // Set sub domain
      const rightProcessDomain = content.processDomains.find((domain) => domain.name === line[1])

      const subDomain = rightProcessDomain.subDomains.find((domain) => domain.name === line[2])

      // Build Question Object
      const question = {
        id: uuidv4(),
        title: line[3] ? line[3] : 'WARNUNG: Keine Frage angegeben',
        level0: line[4] ? line[4] : 'WARNUNG: Antwort nicht angegeben',
        level1: line[5] ? line[5] : 'WARNUNG: Antwort nicht angegeben',
        level2: line[6] ? line[6] : 'WARNUNG: Antwort nicht angegeben',
        level3: line[7] ? line[7] : 'WARNUNG: Antwort nicht angegeben',
        level4: line[8] ? line[8] : 'WARNUNG: Antwort nicht angegeben',
        level5: line[9] ? line[9] : 'WARNUNG: Antwort nicht angegeben',
      }
      // Set questions
      if (subDomain) {
        subDomain.questions.push(question)
      } else {
        // subDomain nicht vorhanden
        if (!line[2]) {
          line[2] = 'WARNUNG: Keine Unterdomäne angegeben'
        }
        rightProcessDomain.subDomains.push({ name: line[2], questions: [question], id: uuidv4() })
      }

    }
  })
  return content
}

const ImportMaturityModel = () => {
  const classes = useStyles()
  const [fileContent, setFileContent] = useState([]);
  const [output, setOutput] = useState({});
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const { id } = useParams()

  let fileReader

  const uploadFile = (file) => {
    fileReader = new FileReader();
    fileReader.onload = function load (e) {
      const data = e.target.result;
      const readData = XLSX.read(data, { type: 'binary' });
      const wsname = readData.SheetNames[0];
      const ws = readData.Sheets[wsname];

      /* Convert array to json */
      const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
      setFileContent(dataParse)
    };
    fileReader.readAsBinaryString(file)
  }

  const save = (data) => {
    const url = getModelImportApi(id)
    apiClientInstance.put(url, data)
      .then(() => {
        enqueueSnackbar('Reifemodell importiert', {
          variant: 'success',
        })
        navigate('../')
      })
      .catch((error) => {
        enqueueSnackbar(`Fehler beim Importieren: ${error}`, {
          variant: 'error',
        })
      })
  }

  useEffect(() => {
    setOutput(transformData(fileContent))
  }, [fileContent])

  return (
    <Page
      className={classes.root}
      title="Reifegradmodelle">
      <Header title="Importieren eines Reifegradmodells">
        <Button
          variant="contained"
          component="label"
        >
          Datei einlesen
          <input
            onChange={(e) => uploadFile(e.target.files[0])}
            type="file"
            hidden
          />
        </Button>
      </Header>
      {output?.processDomains?.length > 0 && (
      <Paper>
        <MaturityModelTree tree={output}/>
        <Container className={classes.button}>
          <Button onClick={() => save(output)} variant="contained">
            Speichern
          </Button>
        </Container>
      </Paper>
      )}
      {output?.processDomains?.length <= 0 && (
        <Paper>
          <Container className={classes.insidePaper}>
            <Typography>
              Keine Datei ausgewählt
            </Typography>
          </Container>
        </Paper>
      )}
    </Page>
  )
}

export default ImportMaturityModel

import React from 'react'
import { Container, Grid, makeStyles, Typography } from '@material-ui/core'
import Page from 'src/components/Page'
import AuthGuard from '../../../components/AuthGuard'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}))

const Report = () => {
  const classes = useStyles()

  return (
    <Page
      className={classes.root}
      title="Bericht-Vorlagen">
      <AuthGuard requiredRole={['admin', 'consultant']}>
        <Container maxWidth={false}>
          <Grid
            container
            spacing={3}>
            <Grid
              item
              lg={3}
              sm={6}
              xl={3}
              xs={12}>
              <Typography>Beispiel Bericht-Vorlagenseite...</Typography>
            </Grid>
          </Grid>
        </Container>
      </AuthGuard>
    </Page>
  )
}

export default Report

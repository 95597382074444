import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import EditForm from '../../../components/crud/EditForm'
import schema from './jsonSchemaBuilder'
import { apiClientInstance } from '../../../apiClient'
import { getClientSettingsApi } from './apiConstants'

const ClientSettingsPage = () => {
  const { clientId } = useParams()
  const [data, setData] = useState({})
  const { enqueueSnackbar } = useSnackbar()

  const save = (formData) => {
    formData.settings = { siteLabelField: formData.siteLabelField }
    apiClientInstance.put(getClientSettingsApi(clientId), formData)
      .then(() => {
        enqueueSnackbar('Erfolgreich gespeichert!', {
          variant: 'success',
        })
      })
      .catch((error) => {
        enqueueSnackbar(`Fehler beim Speicher: ${error}`, {
          variant: 'error',
        })
      })
  }

  const fetchData = () => {
    apiClientInstance.get(getClientSettingsApi(clientId))
      .then((response) => {
        const x = { ...response.data, siteLabelField: response.data.settings.siteLabelField }
        setData(x)
      })
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <EditForm
        schema={schema()}
        formData={data}
        onSubmit={save}
      />
    </div>
  )
}

export default ClientSettingsPage

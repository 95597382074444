import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  header: {
    display: 'flex',
    width: '1',
    justifyContent: 'center',
    backgroundColor: theme.palette.divider,
    alignItems: 'center'
  },
  chip: {
    backgroundColor: theme.palette.primary.light
  },
}))

const MaturityLevel = ({ header, title }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography variant="h5">
          {header}
        </Typography>
      </div>
      <Typography>
        {title}
      </Typography>
    </div>
  )
}

MaturityLevel.propTypes = {
  header: PropTypes.string,
  title: PropTypes.string,
}

export default MaturityLevel

import React from 'react'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'

/**
 * showing switch for check box form fields
 */
const SwitchCheckbox = function (props) {
  // eslint-disable-next-line react/prop-types
  const { value, id, onChange, label } = props
  console.log('props', props)
  return (
    <FormControlLabel
      control={(
        <Switch
          checked={value}
          onChange={() => onChange(!value)}
          name={id}
          inputProps={{ 'aria-label': 'secondary checkbox' }}
          color="primary"
        />
      )}
      label={String(label)}
    />
  )
}

export default SwitchCheckbox

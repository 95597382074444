import React, { useEffect, useState } from 'react'
import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'
import EditForm from '../../../components/crud/EditForm'
import { apiClientInstance } from '../../../apiClient'
import editSchema from './editModelSchema.json'
import Header from '../../../components/Header'
import { getOneModelApi } from './apiConstants'

// show multi-line textfield
const uiSchema = {
  description: {
    'ui:widget': 'textarea',
    'ui:options': {
      rows: 3,
    },
  },
}

const EditMaturityModel = () => {

  // id is not part of new object
  const schema = { ...editSchema }
  delete schema.properties.id
  const { id } = useParams()
  const [data, setData] = useState({ lastUpdateTime: new Date(), id })

  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const save = (formData) => {
    const url = getOneModelApi(id)
    apiClientInstance.put(url, formData)
      .then(() => {
        enqueueSnackbar('Reifemodell bearbeitet', {
          variant: 'success',
        })
        navigate('../')
      })
      .catch((error) => {
        enqueueSnackbar(`Fehler beim Bearbeiten: ${error}`, {
          variant: 'error',
        })
      })
  }

  const fetchData = () => {
    apiClientInstance.get(getOneModelApi(id))
      .then((response) => {
        setData({
          name: response.data.name,
          author: response.data.author,
          version: response.data.version,
          released: response.data.released
        })
      }).catch((error) => {
        enqueueSnackbar(`Fehler beim Laden: ${error}`, {
          variant: 'error',
        })
      })
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <Header title="Reifegradmodell bearbeiten"/>
      <EditForm
        schema={schema}
        uiSchema={uiSchema}
        formData={data}
        onSubmit={save}
      />
    </div>
  )
}
EditMaturityModel.propTypes = {
}

export default EditMaturityModel

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import { Grid, IconButton, TableBody, TableContainer, TableRow, Typography } from '@material-ui/core'
import { Link, useParams } from 'react-router-dom'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableCell from '@material-ui/core/TableCell'
import Paper from '@material-ui/core/Paper'
import EditIcon from '@material-ui/icons/Edit'
import AddIcon from '@material-ui/icons/Add'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import Header from '../../../components/Header'
import SubmitFab from '../../../components/SubmitFab'
import { apiClientInstance } from '../../../apiClient'
import { getClientSitesApi, getClientSystemsApi } from './apiConstants'
import DialogDelete from '../../../components/DialogDelete'

const useStyles = makeStyles((theme) => ({
  root: {
  },
  grid: {
    display: 'flex',
    justifyContent: 'center'
  },
  tableCell: {
    borderBottomColor: theme.palette.divider
  },
}))

const ItSystemsList = ({ className }) => {
  const { clientId } = useParams()
  const classes = useStyles()
  const [dialogOpen, setDialogOpen] = React.useState(false)
  const [currentSelectedEntry, setCurrentSelectedEntry] = React.useState({})
  const [systems, setSystems] = useState([])
  const [sites, setSites] = useState([])

  const fetchData = () => {
    apiClientInstance.get(getClientSystemsApi(clientId))
      .then((response) => {
        setSystems(response.data.content)
      })

    apiClientInstance.get(getClientSitesApi(clientId))
      .then((response) => {
        setSites(response.data.content)
      })
  }

  const getSiteName = (id) => {
    return sites.find((site) => site.id === id)?.name
  }

  const handleDeleteDialogClose = () => {
    setDialogOpen(false)
  }

  const handleDelete = () => {
    apiClientInstance.delete(getClientSystemsApi(clientId) + currentSelectedEntry.itSystemId)
      .then(() => {
        handleDeleteDialogClose()
        fetchData()
      })
      .catch(() => {
        handleDeleteDialogClose()
      })
  }

  const handleDialogOpen = (entry) => {
    setCurrentSelectedEntry(entry)
    setDialogOpen(true)
  }

  const arrayToString = (array) => {
    if (array) {
      if (array.length === 1) {
        return array[0]
      }
      if (array.length > 1) {
        let string = ''
        array.forEach((entry) => {
          string += `${entry}, `
        })
        return string.trim()
          .slice(0, -1)
      }
    }
    return ''
  }

  const sitesToString = (array) => {
    if (!array || array.length === 0) {
      return 'Alle'
    }
    const x = []
    array.forEach((id) => x.push(getSiteName(id)))
    return arrayToString(x)
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={clsx(classes.root, className)}>
      <Header title="IT Systeme"/>
      <DialogDelete
        title={currentSelectedEntry.productName}
        handleClose={handleDeleteDialogClose}
        handleDelete={handleDelete}
        open={dialogOpen}/>
      {systems && systems.length > 0 && (
        <Paper>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableCell}>
                    <h3>
                      Produktname
                    </h3>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <h3>
                      Hersteller
                    </h3>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <h3>
                      Standards
                    </h3>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <h3>
                      Beschreibung
                    </h3>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <h3>
                      Betriebsstätten
                    </h3>
                  </TableCell>
                  <TableCell className={classes.tableCell} />
                </TableRow>
              </TableHead>
              <TableBody>
                {systems.map((entry) => (
                  <TableRow key={entry.itSystemId}>
                    <TableCell className={classes.tableCell}>
                      {entry.productName}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {entry.manufacturer}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {arrayToString(entry.supportedStandards)}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {entry.description}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {sitesToString(entry.clientSiteIds)}
                    </TableCell>
                    <TableCell className={classes.tableCell} align="right">
                      <IconButton component={Link} to={`edit/${entry.itSystemId}`}>
                        <EditIcon/>
                      </IconButton>
                      <IconButton onClick={() => handleDialogOpen(entry)}>
                        <DeleteOutlineIcon/>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      )}
      {systems.length <= 0 && (
        <Grid container spacing={2} className={classes.grid}>
          <Typography align="center">
            Diesem Mandanten sind bisher keine IT-Systeme zugewiesen
          </Typography>
        </Grid>
      )}
      <SubmitFab show component={Link} to="new" text="Hinzufügen">
        <AddIcon/>
      </SubmitFab>
    </div>
  )
}

ItSystemsList.propTypes = {
  className: PropTypes.string,
}

export default ItSystemsList

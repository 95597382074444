import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { IconButton, Typography } from '@material-ui/core'
import Popover from '@material-ui/core/Popover'
import Box from '@material-ui/core/Box'
import List from '@material-ui/core/List'
import ListItemText from '@material-ui/core/ListItemText'
import ListItem from '@material-ui/core/ListItem'

const useStyles = makeStyles(() => ({
  popover: {
    width: 320
  },
}))

const ListPopUp = ({ title, items, children }) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (

    <div>
      <IconButton onClick={handleClick}>
        {children}
      </IconButton>
      <Popover
        classes={{ paper: classes.popover }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        transition="true">
        <Box p={2}>
          <Typography
            variant="h5"
            color="textPrimary"
          >
            {title}
          </Typography>
        </Box>
        {items.length === 0 ? (
          <Box p={2}>
            <Typography
              variant="h6"
              color="textPrimary"
            >
              Hier gibt es gerade nichts
            </Typography>
          </Box>
        ) : (
          <>
            <List disablePadding>
              {items.map((item) => {

                return (
                  <ListItem
                    divider
                    key={item.title}
                  >
                    <ListItemText
                      primary={item.title}
                      primaryTypographyProps={{ variant: 'subtitle2', color: 'textPrimary' }}
                      secondary={item.description}
                    />
                  </ListItem>
                );
              })}
            </List>
          </>
        )}
      </Popover>
    </div>
  )
}

ListPopUp.propTypes = {
  title: PropTypes.string,
  items: PropTypes.array,
  children: PropTypes.element
}

export default ListPopUp

import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import Page from 'src/components/Page'
import PropTypes from 'prop-types'
import ResetPasswordForm from '../../components/ResetPasswordForm'
import { withAuth } from '../../auth'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(15),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  content: {
    width: 535,
  },
  errorText: {
    marginTop: theme.spacing(2),
  },
}))

const ResetPasswordConfirm = ({ auth: { error, completePasswordReset } }) => {

  const classes = useStyles()

  const handleSubmit = (values) => {
    completePasswordReset(values.email, values.code, values.password)
  }

  return (
    <Grid container className={classes.root}>
      <Grid item>
        <Page title="Passwort zurücksetzen bestätigen" className={classes.content}>
          <Typography
            color="textSecondary"
            variant="body1">
            Geben Sie Ihre E-Mail-Adresse und den Verifizierungscode an, den wir Ihnen per Email
            zugesendet haben um ein neues Passwort vergeben zu können.
          </Typography>
          <ResetPasswordForm onSubmit={handleSubmit}/>
          {error && (
          <Typography
            className={classes.errorText}
            color="error"
            variant="subtitle2">
            {error.message}
          </Typography>
          )}
        </Page>
      </Grid>
    </Grid>
  )
}

ResetPasswordConfirm.propTypes = {
  auth: PropTypes.any,
  completePasswordReset: PropTypes.func,
  error: PropTypes.any
}

export default withAuth(ResetPasswordConfirm)

import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import { Field, Form, Formik } from 'formik'
import validate from 'validate.js'
import { TextField } from 'formik-material-ui'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import { Typography } from '@material-ui/core'
import SubmitFab from './SubmitFab'
import { VALIDATION_SCHEMA_EMAIL } from '../constants'

const schema = {
  email: VALIDATION_SCHEMA_EMAIL,
}

const useStyles = makeStyles((theme) => ({
  root: {},
  fab: {
    position: 'fixed',
    bottom: theme.spacing(5),
    right: theme.spacing(5),
  },
  error: {
    marginTop: theme.spacing(1),
  },
}))

const EmailForm = ({ className, onSubmit }) => {
  const classes = useStyles()

  return (
    <div className={clsx(classes.root, className)}>
      <Formik
        initialValues={{
          email: '',
        }}
        validate={(values) => {
          return validate(values, schema, { format: 'flat' })
        }}
        onSubmit={(values) => onSubmit(values)}>
        {({ isSubmitting, isValid, dirty, errors }) => (
          <Form>
            <Field
              fullWidth
              label="Email"
              name="email"
              type="email"
              variant="outlined"
              component={TextField}/>
            <Typography color="error" className={classes.error}>{errors[0]}</Typography>
            <SubmitFab show={dirty} disabled={isSubmitting || !isValid} text="Weiter">
              <NavigateNextIcon/>
            </SubmitFab>
          </Form>
        )}
      </Formik>
    </div>
  )
}

EmailForm.propTypes = {
  className: PropTypes.string,
  onSubmit: PropTypes.func,
}

export default EmailForm

import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card'
import { Button, CardActionArea, Typography } from '@material-ui/core'
import CardMedia from '@material-ui/core/CardMedia'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import PropTypes from 'prop-types'
import moment from 'moment'
import RadarChart from './charts/RadarChart'

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 345,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  displayArea: {
    width: '100%',
    height: '310px',
    backgroundColor: theme.palette.divider,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  placeholderButton: {
    marginTop: theme.spacing(2)
  },
  chartArea: {
    width: '100%',
    height: '100%'
  },
  cardButtons: {
  }
}));

const DisplayCard = ({
  name,
  lastUpdated,
  onRatingsClick,
  onNewClick,
  onHistoryClick,
  onEditClick,
  released,
  entry,
  chartData,
}) => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      {entry.currentStatusId && (
        <CardActionArea onClick={onEditClick}>
          <CardMedia>
            <RadarChart
              className={classes.chartArea}
              chartData={chartData}
              card
          />
          </CardMedia>
          <CardContent>
            <Typography variant="h3">{name}</Typography>
            {lastUpdated
              ? (
                <Typography>
                  Letzter Status vom
                  {' '}
                  {moment(lastUpdated)
                    .format('DD.MM.YY HH:mm')}
                </Typography>
              ) : <Typography>Es wurde noch kein Status erfasst</Typography>}
          </CardContent>
        </CardActionArea>
      )}
      {!entry.currentStatusId && (
        <div>
          <CardMedia
            className={classes.displayArea}>
            <Typography variant="h3">
              Bisher wurde noch kein Status erfasst
            </Typography>
            <Button
              variant="contained"
              className={classes.placeholderButton}
              color="primary"
              onClick={onNewClick}
              disabled={!released}
          >
              Status erfassen
            </Button>
          </CardMedia>
          <CardContent>
            <Typography variant="h3">{name}</Typography>
            {lastUpdated
              ? (
                <Typography>
                  Letzter Status vom
                  {' '}
                  {moment(lastUpdated)
                    .format('DD.MM.YY HH:mm')}
                </Typography>
              ) : <Typography>Es wurde noch kein Status erfasst</Typography>}
          </CardContent>
        </div>

      )}
      <CardActions className={classes.cardButtons}>
        <Button onClick={onNewClick} disabled={!released}>
          Neu
        </Button>
        <Button onClick={onHistoryClick}>
          Historie
        </Button>
        <Button onClick={onRatingsClick}>
          Ziele
        </Button>
      </CardActions>
    </Card>
  );
}

DisplayCard.propTypes = {
  name: PropTypes.string,
  lastUpdated: PropTypes.string,
  onRatingsClick: PropTypes.func,
  onNewClick: PropTypes.func,
  onHistoryClick: PropTypes.func,
  onEditClick: PropTypes.func,
  released: PropTypes.bool,
  entry: PropTypes.object,
  chartData: PropTypes.object,
}

export default DisplayCard

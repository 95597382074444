import React, { useEffect, useState } from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import Page from 'src/components/Page'
import Paper from '@material-ui/core/Paper'
import { useSnackbar } from 'notistack'
import { useParams } from 'react-router-dom'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Slider from '@material-ui/core/Slider'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Tooltip from '@material-ui/core/Tooltip'
import withStyles from '@material-ui/core/styles/withStyles'
import MaturityModelTree from '../../../components/MaturityModelTree'
import { apiClientInstance } from '../../../apiClient'
import Header from '../../../components/Header'
import { getClientModelGoals, getClientModelGoalsPerQuestion, getClientModelsApi } from './apiConstants'
import RadarChart from '../../../components/charts/RadarChart'
import { calculateGoalRadarChartData } from './statusCalculations'

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  sliderField: {
    paddingTop: theme.spacing(2),
  },
  dividerMargin: {
    marginTop: theme.spacing(2),
  },
  subheader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  dialogSliderHeader: {
    display: 'flex',
    justifyContent: 'space-between'
  }
}))

const SizedTooltip = withStyles({
  tooltip: {
    fontSize: 14,
  }
})(Tooltip);

function addGoalDataToQuestions (maturityModel, questionGoalMap) {
  maturityModel.processDomains.forEach((processDomain) => {
    processDomain.subDomains.forEach((subDomain) => {
      subDomain.questions.forEach((question) => {
        const goal = Object.keys(questionGoalMap)
          .find((entry) => entry === question.id)
        question.weight = questionGoalMap[goal].weight
        question.goal = questionGoalMap[goal].targetLevel
      })
    })
  })
}

const ClientsMaturityModelsRatings = () => {
  const classes = useStyles()
  const [output, setOutput] = useState({})
  const [released, setReleased] = useState()
  const [name, setName] = useState('')
  const [version, setVersion] = useState('')
  const [currentEntry, setCurrentEntry] = useState({})
  const [prevEntry, setPrevEntry] = useState({})
  const [open, setOpen] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const { clientId, siteId, id } = useParams()

  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const fetchData = () => {

    apiClientInstance.get(getClientModelsApi(clientId) + id)
      .then((response) => {
        setName(response.data.name)
        setVersion(response.data.version)
        setReleased(response.data.maturityModel.released)
        apiClientInstance.get(getClientModelGoals(clientId, siteId, id))
          .then((modelGoalsResponse) => {
            const { maturityModel } = response.data
            const { questionGoalMap } = modelGoalsResponse.data

            addGoalDataToQuestions(maturityModel, questionGoalMap)
            maturityModel.chartData = calculateGoalRadarChartData(maturityModel)
            setOutput(maturityModel)
          })
      })
      .catch((error) => {
        enqueueSnackbar(`Fehler beim Laden: ${error}`, {
          variant: 'error',
        })
      })

  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const save = (data) => {
    apiClientInstance.put(getClientModelGoalsPerQuestion(clientId, siteId, id, data.id),
      { targetLevel: data.goal, weight: data.weight })
      .then(() => {
        enqueueSnackbar('Erfolgreich gespeichert', {
          variant: 'success',
        })
        fetchData()
        setOpen(false)
      })
      .catch((error) => {
        enqueueSnackbar(`Fehler beim Speichern: ${error}`, {
          variant: 'error',
        })
      })
  }

  const onQuestionClick = (question) => {
    setCurrentEntry({ ...question })
    setPrevEntry({ ...question })
    setOpen(true)
  }

  const handleOk = () => {
    save(currentEntry)
  }

  const handleCancel = () => {
    setCurrentEntry(prevEntry)
    setOpen(false)
  }

  const handleGoalChange = (event, newValue) => {
    const temp = currentEntry
    temp.goal = newValue
    setCurrentEntry(temp)
  }

  const handleWeightChange = (event, newValue) => {
    const temp = currentEntry
    temp.weight = newValue
    setCurrentEntry(temp)
  }

  return (
    <Page
      className={classes.root}
      title={`Reifegradmodell ${name}`}>
      <Header title={`Ziele Für Reifegradmodell ${name} Version ${version}`}/>
      <Dialog
        open={open}
        fullScreen={fullScreen}
        maxWidth="xs"
        fullWidth>
        <DialogContent className={classes.sliderField}>
          <div className={classes.dialogSliderHeader}>
            <Typography>
              Gewichtung
            </Typography>
            <SizedTooltip
              title="Der Einfluss auf das Endergebnis"
              arrow
              enterDelay={0}>
              <InfoOutlinedIcon size="small"/>
            </SizedTooltip>
          </div>
          <Slider
            defaultValue={currentEntry.weight}
            onChange={handleWeightChange}
            aria-labelledby="discrete-slider"
            valueLabelDisplay="auto"
            step={1}
            marks
            min={1}
            max={5}
      />
          <div className={classes.dialogSliderHeader}>
            <Typography>
              Zielreifegrad
            </Typography>
            <SizedTooltip
              title="Der gewünschte Endreifegrad"
              arrow
              enterDelay={0}>
              <InfoOutlinedIcon size="small"/>
            </SizedTooltip>
          </div>
          <Slider
            defaultValue={currentEntry.goal}
            onChange={handleGoalChange}
            aria-labelledby="discrete-slider"
            valueLabelDisplay="auto"
            step={1}
            marks
            min={0}
            max={5}
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCancel} color="primary">
            Abbrechen
          </Button>
          <Button onClick={handleOk} color="primary" variant="contained">
            Speichern
          </Button>
        </DialogActions>
      </Dialog>

      {output.processDomains && output.processDomains.length > 0 && (

        <>
          <Paper>
            <Typography className={classes.subheader}>
              Hier sind die aktuellen Zielvorgaben zum Erreichen des angestrebten Reifegrades
              definiert.
              Einstellungen wirken sich auf die Bewertungen des Status aus.
            </Typography>
            <Divider/>
            <RadarChart chartData={output.chartData} noPercent/>
            <Divider className={classes.dividerMargin}/>
            {released
              ? (
                <MaturityModelTree
                  onQuestionWeightEdit={(question) => onQuestionClick(question)}
                  tree={output}
                />
              )
              : (
                <MaturityModelTree
                  tree={output}
                />
              )}
          </Paper>
        </>
      )}
    </Page>
  )
}

ClientsMaturityModelsRatings.propTypes = {}

export default ClientsMaturityModelsRatings

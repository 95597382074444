const PASSWORD_PATTERN = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?\-“!@#%&/,><’:;|_~`])\S*$/

export const VALIDATION_SCHEMA_PASSWORD = {
  presence: { allowEmpty: false, message: '^Passwort erforderlich' },
  length: {
    maximum: 99,
    minimum: 6,
    message: '^Das Passwort muss zwischen 6 und 99 Zeichen lang sein',
  },
  format: {
    pattern: PASSWORD_PATTERN,
    message: '^Groß- und Kleinbuchstaben, Zahlen und Sonderzeichen erforderlich',
  },
}

export const VALIDATION_SCHEMA_EMAIL = {
  presence: { allowEmpty: false, message: '^Email erforderlich' },
  email: {
    message: '^Fehlerhafte Email',
  },
}

import React, { useState } from 'react'
import { useSnackbar } from 'notistack'
import { useLocation, useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'
import EditForm from '../../../components/crud/EditForm'
import { apiClientInstance } from '../../../apiClient'
import editSchema from './questionSchema.json'
import uiSchema from './questionsUiSchema.json'
import Header from '../../../components/Header'
import { getModelQuestionByDomainApi } from './apiConstants'

const AddQuestion = () => {
  const { state } = useLocation()

  // id is not part of new object
  const schema = { ...editSchema }
  delete schema.properties.id
  const { id } = useParams()
  const [data] = useState({})
  const { processDomainId, subDomainId } = state

  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const save = (formData) => {
    const url = getModelQuestionByDomainApi(id, processDomainId, subDomainId)
    apiClientInstance.post(url, formData)
      .then(() => {
        enqueueSnackbar('Frage gespeichert', {
          variant: 'success',
        })
        navigate('../../')
      })
      .catch((error) => {
        enqueueSnackbar(`Fehler beim Erstellen: ${error}`, {
          variant: 'error',
        })
      })
  }

  return (
    <div>
      <Header title="Frage erstellen"/>
      <EditForm
        schema={schema}
        uiSchema={uiSchema}
        formData={data}
        onSubmit={save}
      />
    </div>
  )
}
AddQuestion.propTypes = {
}

export default AddQuestion

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { makeStyles } from '@material-ui/styles'
import { Typography } from '@material-ui/core'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';
import Button from '@material-ui/core/Button'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2)
  },
  subHeader: {
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2)
  },
}))

const ItSystemDialog = ({
  onClose,
  onSave,
  open,
  subDomain,
  systems,
  notChosenSystems
}) => {
  const classes = useStyles()
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [assignedSystems, setAssignedSystems] = useState([])
  const [remainingSystems, setRemainingSystems] = useState([])

  useEffect(() => {
    setRemainingSystems(notChosenSystems)
    setAssignedSystems(systems)
  }, [notChosenSystems, systems])

  const onAdd = (system) => {
    const index = remainingSystems.indexOf(system);
    if (index > -1) {
      const temp = [...remainingSystems]
      temp.splice(index, 1)
      setRemainingSystems(temp);
    }
    const temp = assignedSystems
    temp.push(system)
    setAssignedSystems(temp)
  }

  const onRemove = (system) => {
    const index = assignedSystems.indexOf(system);
    if (index > -1) {
      const temp = [...assignedSystems]
      temp.splice(index, 1)
      setAssignedSystems(temp);
    }
    const temp = remainingSystems
    temp.push(system)
    setRemainingSystems(temp)
  }

  return (
    <Dialog
      open={open}
      fullScreen={fullScreen}
      maxWidth="sm"
      fullWidth>
      <DialogTitle disableTypography>
        <Typography variant="h3">
          {subDomain.name}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <List>
          <Typography variant="overline" className={classes.subHeader}>
            Zugewiesene IT Systeme
          </Typography>
          {assignedSystems.map((system) => (
            <ListItem key={system.itSystemId} onClick={() => onRemove(system)}>
              <ListItemIcon>
                <DesktopWindowsIcon/>
              </ListItemIcon>
              <ListItemText primary={system.productName}/>
            </ListItem>
          ))}
        </List>
        <Divider/>
        <List>
          <Typography variant="overline" className={classes.subHeader}>
            Verfügbare IT Systeme
          </Typography>
          {remainingSystems.map((system) => (
            <ListItem key={system.itSystemId} onClick={() => onAdd(system)}>
              <ListItemIcon>
                <DesktopWindowsIcon/>
              </ListItemIcon>
              <ListItemText primary={system.productName}/>
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onClose} color="primary">
          Abbrechen
        </Button>
        <Button
          onClick={() => {
            onSave(assignedSystems)
          }}
          variant="contained"
          color="primary"
          autoFocus>
          Bestätigen
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ItSystemDialog.propTypes = {
  open: PropTypes.bool,
  subDomain: PropTypes.object,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
  systems: PropTypes.array,
  notChosenSystems: PropTypes.array
}

export default ItSystemDialog

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Divider, IconButton, makeStyles, Typography } from '@material-ui/core'
import Page from 'src/components/Page'
import Button from '@material-ui/core/Button'
import { Link, useParams } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import Paper from '@material-ui/core/Paper'
import EditIcon from '@material-ui/icons/Edit'
import { useNavigate } from 'react-router'
import moment from 'moment';
import Header from '../../../components/Header'
import { apiClientInstance } from '../../../apiClient'
import MaturityModelTree from '../../../components/MaturityModelTree'
import DialogDelete from '../../../components/DialogDelete'
import { getOneModelApi } from './apiConstants'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  subheader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  paper: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  }
}))

const Subheader = ({ infos }) => {
  const classes = useStyles()

  const booleanToString = (bool) => {
    if (bool) {
      return 'freigegeben'
    }
    return 'gesperrt'

  }

  const lastUpdated = moment(infos.lastUpdated)

  return (
    <div>
      {infos.lastUpdated
      && (
        <div className={classes.subheader}>
          <Typography>
            Author:
            {' '}
            {infos.author}
          </Typography>
          <Typography>
            Version:
            {' '}
            {infos.version}
          </Typography>
          <Typography>
            {booleanToString(infos.released)}
          </Typography>
          <Typography>
            Zuletzt bearbeitet:
            {' '}
            {lastUpdated.format('DD.MM.YYYY hh:mm')}
          </Typography>
          <IconButton component={Link} to="edit" size="small">
            <EditIcon/>
          </IconButton>
        </div>
      )}
    </div>
  )
}

Subheader.propTypes = {
  infos: PropTypes.object,
}

const DetailedMaturityModel = () => {
  const classes = useStyles()
  const { id } = useParams()
  const { enqueueSnackbar } = useSnackbar()
  const [data, setData] = useState({});
  const [infos, setInfos] = useState({})
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [toDeleteId, setToDeleteId] = React.useState('');
  const [toDeleteName, setToDeleteName] = React.useState('');
  const [toDeleteUrl, setToDeleteUrl] = React.useState('');

  const handleDialogOpen = (entry, url) => {
    setToDeleteId(entry.id)
    setToDeleteName(entry.name)
    setToDeleteUrl(url)
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const fetchData = () => {
    apiClientInstance.get(getOneModelApi(id))
      .then((response) => {
        setData({ processDomains: response.data.processDomains })
        setInfos({ name: response.data.name,
          author: response.data.author,
          version: response.data.version,
          released: response.data.released,
          lastUpdated: new Date(response.data.lastUpdateTime),
        })
      }).catch((error) => {
        enqueueSnackbar(`Fehler beim Laden: ${error}`, {
          variant: 'error',
        })
      })
  }

  const handleDelete = () => {
    const url = `${getOneModelApi(id)}/${toDeleteUrl}/${toDeleteId}`
    apiClientInstance.delete(url)
      .then(() => {
        handleDialogClose()
        fetchData()
        enqueueSnackbar(` ${toDeleteName} Erfolgreich gelöscht!`, {
          variant: 'success',
        })
      }).catch((error) => {
        enqueueSnackbar(`Fehler beim Löschen: ${error}`, {
          variant: 'error',
        })
        handleDialogClose()
      })
  }

  const navigate = useNavigate()

  const onQuestionEdit = (value) => {
    navigate(`edit/question/${value.id}`)
  }

  const onProcessDomainEdit = (value) => {
    navigate(`edit/processdomain/${value}`)
  }

  const onSubDomainEdit = (value, processDomainId) => {
    navigate(`edit/subdomain/${value}`, { state: { processDomainId } })
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Page
      className={classes.root}
      title="Reifegradmodelle">
      <DialogDelete
        title={toDeleteName}
        handleClose={handleDialogClose}
        handleDelete={handleDelete}
        open={dialogOpen}/>
      {data?.processDomains && !data?.processDomains?.length > 0 && (
      <Header title={`Detailansicht Reifegradmodell ${infos.name}`}>
        <Button
          component={Link}
          to="import"
          variant="contained">
          Importieren
        </Button>
      </Header>
      )}
      {data?.processDomains?.length > 0 && (
      <Header title={`Detailansicht Reifegradmodell ${infos.name}`} />
      )}
      <Paper>
        <Subheader infos={infos}/>
        <Divider/>
        <MaturityModelTree
          tree={data}
          onProcessDomainEdit={((domainId) => onProcessDomainEdit(domainId))}
          onSubDomainEdit={
            (domainId, processDomainId) => onSubDomainEdit(domainId, processDomainId)
          }
          onQuestionEdit={(question) => onQuestionEdit(question)}
          onDelete={(entry, url) => handleDialogOpen(entry, url)}
          addOption
            />
      </Paper>
    </Page>
  )
}

export default DetailedMaturityModel

import React from 'react'
import { Container, Grid, makeStyles } from '@material-ui/core'
import Page from 'src/components/Page'
import { Routes, Route } from 'react-router-dom'
import AuthGuard from '../../../components/AuthGuard'
import ActionsList from './ActionsList'
import EditActionField from './EditActionField'
import NewActionField from './NewActionField'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}))

const FieldsOfAction = () => {
  const classes = useStyles()

  return (
    <Page
      className={classes.root}
      title="Handlungsfelder">
      <AuthGuard requiredRole={['admin', 'consultant']}>

        <Container maxWidth={false}>
          <Grid
            container
            spacing={3}>
            <Grid
              item
              lg={12}
              sm={12}
              xl={12}
              xs={12}>
              <Routes>
                <Route name="actionList" path="/" element={<ActionsList/>}/>
                <Route path="/?page=:page" element={<ActionsList/>}/>
                <Route path="new" element={<NewActionField/>}/>
                <Route path=":id/edit" element={<EditActionField/>}/>
              </Routes>
            </Grid>
          </Grid>
        </Container>
      </AuthGuard>
    </Page>
  )
}

export default FieldsOfAction

import React from 'react'
import { ResponsiveBar } from '@nivo/bar'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'

const BarComponent = (props) => {
  const navigate = useNavigate()
  return (
    <g
      transform={`translate(${props.x},${props.y})`}
      onClick={() => navigate(`../sites/${props.data.data.id}/maturitymodel`)}>
      <rect
        x={-3}
        y={7}
        width={props.width}
        height={props.height}
        fill="rgba(0, 0, 0, .07)"
      />
      <rect width={props.width} height={props.height} fill={props.color} />
      <rect
        x={props.width - 5}
        width={5}
        height={props.height}
        fill={props.borderColor}
        fillOpacity={0.2}
      />
      <text
        x={-16}
        y={props.height / 2 - 8}
        textAnchor="end"
        dominantBaseline="central"
        fill="black"
        style={{
          fontWeight: 900,
          fontSize: 15,
        }}
      >
        {props.data.data.site}
      </text>
      <text
        x={-16}
        y={props.height / 2 + 10}
        textAnchor="end"
        dominantBaseline="central"
        fill={props.borderColor}
        style={{
          fontWeight: 400,
          fontSize: 13,
        }}
      >
        {`${props.data.value}%`}
      </text>
    </g>
  )
}

const RaceChart = ({ barData }) => {

  return (
    <Box height={400}>
      <ResponsiveBar
        layout="horizontal"
        margin={{ top: 26, right: 40, bottom: 50, left: 150 }}
        data={barData.data}
        keys={barData.keys}
        maxValue={100}
        colors={barData.data.map((entry) => entry.color)}
        colorBy="indexValue"
        borderColor={{ from: 'color', modifiers: [['darker', 2.6]] }}
        enableGridX={false}
        enableGridY={false}
        axisBottom={{
          format: '~s',
        }}
        axisLeft={null}
        padding={0.3}
        labelTextColor={{ from: 'color', modifiers: [['darker', 1.4]] }}
        barComponent={BarComponent}
        motionStiffness={170}
        motionDamping={26}
      />
    </Box>
  )
}

RaceChart.propTypes = {
  barData: PropTypes.object
}

export default RaceChart

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import Paper from '@material-ui/core/Paper'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import { Typography } from '@material-ui/core'
import { useNavigate, useParams } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import moment from 'moment'
import { apiClientInstance } from '../../../apiClient'
import Header from '../../../components/Header'
import { getClientModelPerSiteApi, getClientModelsApi, getClientModelStatusApi } from './apiConstants'
import { calculateAchievedPercentage } from './statusCalculations'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(5),
    right: theme.spacing(5),
  },
  tableCell: {
    borderBottomColor: theme.palette.divider
  },
  tableFooter: {
    marginRight: theme.spacing(8),
  }
}))

const ModelStatusList = ({ className }) => {
  const { clientId, siteId, id } = useParams()
  const classes = useStyles()
  const navigate = useNavigate();

  const [states, setStates] = useState([])
  const [name, setName] = useState('')
  const [goals, setGoals] = useState({})
  const { enqueueSnackbar } = useSnackbar()

  const fetchData = () => {
    apiClientInstance.get(getClientModelStatusApi(clientId, siteId, id), { size: 200 })
      .then((response) => {
        setStates(response.data.content)
      }).catch((error) => {
        enqueueSnackbar(`Fehler beim Laden: ${error}`, {
          variant: 'error',
        })
      })
    apiClientInstance.get(getClientModelsApi(clientId) + id)
      .then((response) => {
        setName(response.data.name)
      })
      .catch((error) => {
        enqueueSnackbar(`Fehler beim Laden: ${error}`, {
          variant: 'error',
        })
      })
    apiClientInstance.get(getClientModelPerSiteApi(clientId, siteId) + id)
      .then((response) => {
        setGoals(response.data.questionGoalMap)
      })
  }

  const redirectOneStatus = (statusId) => {
    navigate(`../${statusId}`)
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <div className={clsx(classes.root, className)}>
      <Header title={`Statushistorie von ${name}`}/>
      <Paper>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableCell} style={{ width: '20%' }}>
                  <h3>
                    Datum
                  </h3>
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <h3>
                    Erfasser
                  </h3>
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <h3>
                    Abteilung
                  </h3>
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <h3>
                    Gesperrt
                  </h3>
                </TableCell>
                <TableCell className={classes.tableCell} style={{ width: '13%' }}>
                  <h3>
                    Erreicht
                  </h3>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody className={classes.root}>
              {states && states.map((entry) => (
                <TableRow key={entry.statusId} onClick={() => redirectOneStatus(entry.statusId)}>
                  <TableCell className={classes.tableCell}>
                    <Typography>
                      {moment(entry.date).format('DD.MM.YYYY')}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Typography>
                      {entry.author}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Typography>
                      {entry.department}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Typography>
                      {entry.locked ? 'JA' : 'NEIN'}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell} >
                    <Typography>
                      {`${goals && entry.answers ? calculateAchievedPercentage(entry.answers, goals) : '-'} %`}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
              {!(states.length > 0) && (
                <TableRow>
                  <TableCell>
                    <Typography align="center">
                      Keine Status für dieses Modell in dieser Betriebsstätte gefunden
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  )
}

ModelStatusList.propTypes = {
  className: PropTypes.string,
}

export default ModelStatusList

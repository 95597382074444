import React, { useEffect, useState } from 'react'
import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'
import EditForm from '../../../components/crud/EditForm'
import { apiClientInstance } from '../../../apiClient'
import editSchema from './schema-site.json'
import Header from '../../../components/Header'
import { getClientSitesApi } from './apiConstants'

const EditClient = () => {

  // id is not part of new object
  const schema = { ...editSchema }
  delete schema.properties.id
  const { id, siteId } = useParams()

  const urlbase = getClientSitesApi(id)
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const [data, setData] = useState({})
  const save = (formData) => {
    apiClientInstance.put(urlbase + siteId, formData)
      .then(() => {
        enqueueSnackbar('Gespeichert', {
          variant: 'success',
        })
        navigate(-1)
      })
      .catch((error) => {
        console.log(error)
        enqueueSnackbar(`Fehler beim Speichern: ${error}`, {
          variant: 'error',
        })
      })
  }

  const fetchData = () => {
    apiClientInstance.get(urlbase + siteId)
      .then((response) => {
        if (response.data.contact.salutation === null) {
          response.data.contact.salutation = ''
        }
        setData(response.data)
      }).catch((error) => {
        enqueueSnackbar(`Fehler beim Laden: ${error}`, {
          variant: 'error',
        })
      })
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <Header title="Betriebsstätte bearbeiten"/>
      <EditForm
        schema={schema}
        formData={data}
        onSubmit={save}
      />
    </div>
  )
}

export default EditClient

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/styles'
import { Button, Typography } from '@material-ui/core'
import { isFunction } from 'formik'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import DialogContentText from '@material-ui/core/DialogContentText'
import MaturityLevel from '../pages/clientView/maturityModels/MaturityLevel'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2)
  },
  dialog: {
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3)
  },
  radioLabel: {
    display: 'flex',
    fullWidth: true,
    width: '100%',
  },
  dialogContent: {
    width: '100%',
  },
}))

const QuestionDialog = ({
  question,
  onClose,
  onNext,
  onSave,
  open,
  processDomain,
  subDomain,
  totalQuestions,
}) => {
  const classes = useStyles()
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  if (!question.currentAnswer) {
    question.currentAnswer = 'null'
  }

  const [originalLevel, setOriginalLevel] = useState()
  const [originalComment, setOriginalComment] = useState('')
  const [level, setLevel] = useState(question.currentAnswer);
  const [comment, setComment] = useState(question.comment);
  const [unsaved, setUnsaved] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const [closeOperation, setCloseOperation] = useState('close')

  const handleCommentChange = (event) => {
    if (!unsaved) {
      setOriginalComment(question.comment)
    }
    question.comment = event.target.value
    setUnsaved(true)
  }

  const handleChange = (event) => {
    if (!unsaved) {
      setOriginalLevel(question.currentAnswer)
    }
    setLevel(event.target.value);
    question.currentAnswer = event.target.value
    setUnsaved(true)
  };

  const handleClose = () => {
    if (unsaved && isFunction(onSave)) {
      setCloseOperation('close')
      setOpenConfirmDialog(true)
    } else {
      onClose()
    }
  }

  const handleNext = () => {
    if (unsaved && isFunction(onSave)) {
      setCloseOperation('next')
      setOpenConfirmDialog(true)
    } else {
      onNext(question)
    }
  }

  const resetQuestion = () => {
    question.currentAnswer = originalLevel
    question.comment = originalComment
    setLevel(originalLevel ? originalLevel.toString() : 'null')
    setComment(originalComment || '')
  }

  useEffect(() => {
    setLevel(question.currentAnswer ? question.currentAnswer.toString() : 'null')
    setComment(question.comment ? question.comment : '')
  }, [question, unsaved])

  return (
    <Dialog
      open={open}
      fullScreen={fullScreen}
      maxWidth="md"
      fullWidth>
      <Dialog
        open={openConfirmDialog}
        fullScreen={fullScreen}
        maxWidth="md">
        <DialogTitle>
          Fortfahren ohne speichern
        </DialogTitle>
        <DialogContentText className={classes.dialog}>
          Sind sie sich sicher, dass sie ohne spiechern fortfahren wollen?
        </DialogContentText>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenConfirmDialog(false)
              resetQuestion()
              switch (closeOperation) {
                case 'close':
                  setUnsaved(false)
                  onClose();
                  break;
                case 'next': {
                  setUnsaved(false)
                  onNext(question)
                  break;
                }
                default: {
                  break;
                }
              }
            }}
            color="primary"
          >
            Bestätigen
          </Button>
          <Button
            onClick={() => setOpenConfirmDialog(false)}
            variant="contained"
            color="primary"
          >
            Abbrechen
          </Button>
        </DialogActions>
      </Dialog>
      <DialogTitle disableTypography>
        <Typography variant="body1">
          {`Frage ${question.count}/${totalQuestions}`}
        </Typography>
        <Typography variant="h5" className={classes.root}>
          {`${processDomain} > ${subDomain}`}
        </Typography>
        <Typography variant="h3">
          {question.title}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <FormControl component="fieldset" className={classes.dialogContent}>
          <RadioGroup value={level} onChange={handleChange}>
            <FormControlLabel
              classes={{ label: classes.radioLabel }}
              value="null"
              control={<Radio color="primary"/>}
              label={<MaturityLevel header="keine Antwort"/>}
            />
            <FormControlLabel
              classes={{ label: classes.radioLabel }}
              value="-1"
              control={<Radio color="primary"/>}
              label={<MaturityLevel header="nicht anwendbar"/>}
            />
            <FormControlLabel
              classes={{ label: classes.radioLabel }}
              value="0"
              control={<Radio color="primary"/>}
              label={<MaturityLevel header="Reifegrad 0" title={question.level0}/>}
            />
            <FormControlLabel
              classes={{ label: classes.radioLabel }}
              value="1"
              control={<Radio color="primary"/>}
              label={<MaturityLevel header="Reifegrad 1" title={question.level1}/>}
            />
            <FormControlLabel
              classes={{ label: classes.radioLabel }}
              value="2"
              control={<Radio color="primary"/>}
              label={<MaturityLevel header="Reifegrad 2" title={question.level2}/>}
            />
            <FormControlLabel
              classes={{ label: classes.radioLabel }}
              value="3"
              control={<Radio color="primary"/>}
              label={<MaturityLevel header="Reifegrad 3" title={question.level3}/>}
            />
            <FormControlLabel
              classes={{ label: classes.radioLabel }}
              value="4"
              control={<Radio color="primary"/>}
              label={<MaturityLevel header="Reifegrad 4" title={question.level4}/>}
            />
            <FormControlLabel
              classes={{ label: classes.radioLabel }}
              value="5"
              control={<Radio color="primary"/>}
              label={<MaturityLevel header="Reifegrad 5" title={question.level5}/>}
            />
          </RadioGroup>
        </FormControl>
        <TextField
          className={classes.dialogContent}
          id="commentField"
          label="Kommentar"
          multiline
          rows={4}
          defaultValue={comment}
          variant="outlined"
          key={comment}
          onChange={handleCommentChange}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          color="primary">
          Abbrechen
        </Button>
        <Button
          onClick={() => {
            setUnsaved(false)
            onSave(question)
          }}
          disabled={!isFunction(onSave) || !unsaved}
          color="primary"
          variant="contained"
        >
          Speichern
        </Button>
        <Button
          onClick={handleNext}
          color="primary">
          Weiter
        </Button>
      </DialogActions>
    </Dialog>
  )
}

QuestionDialog.propTypes = {
  open: PropTypes.bool,
  processDomain: PropTypes.string,
  subDomain: PropTypes.string,
  onNext: PropTypes.func,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
  question: PropTypes.object,
  totalQuestions: PropTypes.number,
}

export default QuestionDialog

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import Paper from '@material-ui/core/Paper'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import { IconButton, Typography } from '@material-ui/core'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { Link } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import AddIcon from '@material-ui/icons/Add'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { apiClientInstance } from '../../../apiClient'
import DialogDelete from '../../../components/DialogDelete'
import Header from '../../../components/Header'
import SubmitFab from '../../../components/SubmitFab'
import TableFooter from '../../../components/TableFooter'
import { baseFieldsOfActionApi, getOneFieldApi } from './apiConstants'

const useStyles = makeStyles((theme) => ({
  root: {},
  fab: {
    position: 'fixed',
    bottom: theme.spacing(5),
    right: theme.spacing(5),
  },
  tableCell: {
    borderBottomColor: theme.palette.divider
  },
  tableFooter: {
    marginRight: theme.spacing(8),
  },
  placeholder: {
    height: theme.spacing(10)
  }
}))

const ActionsList = ({ className }) => {
  const classes = useStyles()

  const [fieldsOfAction, setFieldsOfAction] = useState([])
  const [allItemsCount, setAllItemsCount] = useState(0)
  const [totalPages, setTotalPages] = useState(0)
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [toDeleteEntry, setToDeleteEntry] = React.useState({});
  const { enqueueSnackbar } = useSnackbar()

  const fetchData = (params) => {
    apiClientInstance.get(baseFieldsOfActionApi, params)
      .then((response) => {
        setFieldsOfAction(response.data.content)
        setAllItemsCount(response.data.totalElements)
        setTotalPages(response.data.totalPages)
      }).catch((error) => {
        enqueueSnackbar(`Fehler beim Laden: ${error}`, {
          variant: 'error',
        })
      })

  }

  const handleDialogOpen = (entry) => {
    setToDeleteEntry(entry)
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleDelete = () => {
    const url = getOneFieldApi(toDeleteEntry.id)
    apiClientInstance.delete(url)
      .then(() => {
        handleDialogClose()
        fetchData()
        enqueueSnackbar(` ${toDeleteEntry.name} Erfolgreich gelöscht!`, {
          variant: 'success',
        })
      }).catch((error) => {
        enqueueSnackbar(`Fehler beim Löschen: ${error}`, {
          variant: 'error',
        })
        handleDialogClose()
      })
  }

  return (
    <div className={clsx(classes.root, className)}>
      <Header title="Handlungsfelder"/>
      <DialogDelete
        title={toDeleteEntry.name}
        handleClose={handleDialogClose}
        handleDelete={handleDelete}
        dialogText="Das Handlungsfeld wird von allen aktuellen Nutzungen entfernt"
        open={dialogOpen}/>
      <Paper>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableCell} style={{ width: '20%' }}>
                  <h3>
                    Name
                  </h3>
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <h3>
                    Beschreibung
                  </h3>
                </TableCell>
                <TableCell className={classes.tableCell} style={{ width: '13%' }}/>
              </TableRow>
            </TableHead>
            <TableBody>
              {fieldsOfAction && fieldsOfAction.map((entry) => (
                <TableRow key={entry.id}>
                  <TableCell className={classes.tableCell}>
                    <Typography>
                      {entry.name}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Typography>
                      {entry.description}
                    </Typography>
                  </TableCell>
                  <TableCell align="right" className={classes.tableCell}>
                    <IconButton onClick={() => handleDialogOpen(entry)}>
                      <DeleteOutlineIcon/>
                    </IconButton>
                    <IconButton component={Link} to={`${entry.id}/edit`}>
                      <ArrowForwardIcon/>
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              {!(fieldsOfAction.length > 0) && (
                <TableRow>
                  <TableCell width="100%">
                    <Typography align="center">
                      Keine Handlungsfelder gefunden
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TableFooter
          allItemsCount={allItemsCount}
          fetchData={(params) => fetchData(params)}
          totalPages={totalPages}
        />
        <SubmitFab show component={Link} to="new" text="Hinzufügen">
          <AddIcon/>
        </SubmitFab>
      </Paper>
      <div className={classes.placeholder}/>
    </div>
  )
}

ActionsList.propTypes = {
  className: PropTypes.string,
}

export default ActionsList

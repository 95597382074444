import React from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  item: {
    fontWeight: 500,
  }
}))

const DeleteDialog = ({ title, handleDelete, handleClose, open, dialogText }) => {
  const theme = useTheme();
  const classes = useStyles()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {'Soll '}
          <Typography color="primary" className={classes.item} noWrap display="inline">
            {title}
          </Typography>
          {' gelöscht werden?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {dialogText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Abbrechen
          </Button>
          <Button
            onClick={handleDelete}
            color="primary"
            autoFocus>
            Bestätigen
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

DeleteDialog.propTypes = {
  title: PropTypes.string,
  handleDelete: PropTypes.func,
  handleClose: PropTypes.func,
  open: PropTypes.bool,
  dialogText: PropTypes.string,
}

export default DeleteDialog

import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import Page from 'src/components/Page'
import PropTypes from 'prop-types'
import { useSnackbar } from 'notistack'
import EmailForm from '../../components/EmailForm'
import { withAuth } from '../../auth'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(15),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  form: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  content: {
    width: 535,
  },
}))

const ResetPassword = ({ auth: { error, initPasswordReset } }) => {

  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()

  const handleSubmit = (values) => {
    initPasswordReset(values.email)
    enqueueSnackbar('Passwort zurückgesetzt!', {
      variant: 'success',
    })
  }

  return (
    <Grid container className={classes.root}>
      <Grid item>
        <Page title="Passwort zurücksetzen" className={classes.content}>
          <Typography
            color="textSecondary"
            variant="body1">
            Geben Sie Ihre E-Mail-Adresse an
          </Typography>
          <EmailForm className={classes.form} onSubmit={handleSubmit}/>
          {error && (
          <Typography
            color="error"
            variant="subtitle2">
            {error.message}
          </Typography>
          )}
        </Page>
      </Grid>
    </Grid>
  )
}

ResetPassword.propTypes = {
  auth: PropTypes.any,
  initPasswordReset: PropTypes.func,
  error: PropTypes.any
}

export default withAuth(ResetPassword)

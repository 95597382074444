import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import TablePagination from '@material-ui/core/TablePagination'
import { useLocation, useNavigate } from 'react-router-dom'

const useStyles = makeStyles(() => ({
  root: {
  },
}))

// Floating Action Button for submitting forms
const TableFooter = ({ allItemsCount, fetchData, totalPages }) => {

  const classes = useStyles()
  const navigate = useNavigate()
  const page = useLocation().search.replace('?page=', '');
  let pageNumber = page ? parseInt(page, 10) : 0
  if (Number.isNaN(pageNumber)) {
    pageNumber = 0;
  }
  const [pageCount, setPage] = useState(pageNumber)
  const [pageSize, setPageSize] = useState(30)

  const handleChangeRowsPerPage = (event) => {
    setPageSize(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    if (totalPages >= 0) {
      if (totalPages < pageNumber) {
        setPage(0)
      } else {
        navigate(`?page=${pageCount}`)
        fetchData({ page: pageCount, size: pageSize })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageCount, pageSize, allItemsCount, pageNumber])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <div>
      {allItemsCount > 0
    && (
      <TablePagination
        className={classes.root}
        component="div"
        count={allItemsCount}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        page={pageCount}
        rowsPerPage={pageSize}
        rowsPerPageOptions={[5, 10, 25, 30]}
      />
    )}
    </div>
  )
}

TableFooter.propTypes = {
  allItemsCount: PropTypes.number,
  fetchData: PropTypes.func,
  totalPages: PropTypes.number,
}

export default TableFooter

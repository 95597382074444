import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import TreeItem from '@material-ui/lab/TreeItem'
import TreeView from '@material-ui/lab/TreeView'
import React from 'react'
import { IconButton, makeStyles } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import Typography from '@material-ui/core/Typography'
import { isFunction } from 'formik'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import Chip from '@material-ui/core/Chip'
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  questionField: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  iconButton: {
    alignSelf: 'flex-start'
  },
  question: {
    whiteSpace: 'pre-line'
  },
  weightField: {
    paddingRight: theme.spacing(1)
  },
  systemsField: {
    paddingLeft: theme.spacing(1)
  }
}))

const MaturityModelTree = ({ tree, onProcessDomainEdit, onSubDomainEdit,
  onQuestionEdit, onDelete, addOption, onQuestionWeightEdit, onQuestionAnswer,
  itSystems, onSystemsEdit
}) => {
  const classes = useStyles()
  const navigate = useNavigate()

  const addQuestion = (processDomainId, subDomainId) => {
    navigate('add/questions', { state: { processDomainId, subDomainId } })
  }

  const addSubdomain = (processDomainId) => {
    navigate('add/subdomains', { state: { processDomainId } })
  }

  const addProcessDomain = () => {
    navigate('add/processdomains')
  }

  const answerQuestion = (question, processDomain, subDomain, event) => {
    if (isFunction(onQuestionAnswer)) {
      onQuestionAnswer(question, processDomain, subDomain, event)
    }
  }

  return (
    <TreeView
      className={classes.root}
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
    >
      {tree.processDomains && tree.processDomains.map((processDomain) => (
        <TreeItem
          key={processDomain.id}
          nodeId={processDomain.id}
          label={(
            <div className={classes.questionField}>
              <Typography>
                {processDomain.name}
              </Typography>
              <div>
                {isFunction(onProcessDomainEdit) && (
                <IconButton size="small" className={classes.iconButton} onClick={() => onProcessDomainEdit(processDomain.id)}>
                  <EditIcon/>
                </IconButton>
                )}
                {isFunction(onDelete) && (
                <IconButton size="small" className={classes.iconButton} onClick={() => onDelete({ id: processDomain.id, name: processDomain.name }, 'processdomains')}>
                  <DeleteOutlineIcon/>
                </IconButton>
                )}
              </div>
            </div>
        )}>
          {processDomain.subDomains && processDomain.subDomains.map((subDomain) => (
            <TreeItem
              key={subDomain.id}
              nodeId={subDomain.id}
              label={(
                <div className={classes.questionField}>
                  <Typography>
                    {subDomain.name}
                  </Typography>
                  <div>
                    {
                    itSystems && (
                    <div>
                      <IconButton
                        size="small"
                        className={classes.iconButton}
                        onClick={() => onSystemsEdit(subDomain)}>
                        <DesktopWindowsIcon/>
                      </IconButton>
                    </div>
                    )
                    }
                    {isFunction(onSubDomainEdit) && (
                    <IconButton size="small" className={classes.iconButton} onClick={() => onSubDomainEdit(subDomain.id, processDomain.id)}>
                      <EditIcon/>
                    </IconButton>
                    )}
                    {isFunction(onDelete) && (
                    <IconButton size="small" className={classes.iconButton} onClick={() => onDelete({ id: subDomain.id, name: subDomain.name }, `processdomains/${processDomain.id}/subdomains`)}>
                      <DeleteOutlineIcon/>
                    </IconButton>
                    )}
                  </div>
                </div>
            )}>
              {subDomain.questions && subDomain.questions.map((question) => (
                <TreeItem
                  key={question.id}
                  nodeId={question.id}
                  onLabelClick={(event) => (answerQuestion(question, processDomain.name,
                    subDomain.name, event))}
                  label={(
                    <div className={classes.questionField}>
                      <Typography>
                        {question.title}
                      </Typography>
                      <div className={classes.questionField}>
                        {question.weight >= 0 && question.goal >= 0
                        && isFunction(onQuestionWeightEdit)
                        && (
                        <Chip
                          onClick={() => onQuestionWeightEdit(question)}
                          label={`${question.weight} | ${question.goal}`}
                        />
                        )}
                        {question.weight >= 0 && question.goal >= 0
                        && !isFunction(onQuestionWeightEdit)
                        && (
                          <Chip
                            label={`${question.weight} | ${question.goal}`}/>
                        )}
                        {((question.currentAnswer
                        && question.currentAnswer >= -1
                        && question.currentAnswer <= 5)
                          || question.currentAnswer === 0)
                        && (
                          <Chip label={question.currentAnswer === -1 ? 'x' : question.currentAnswer}/>
                        )}
                        {isFunction(onQuestionEdit) && (
                        <IconButton size="small" className={classes.iconButton} onClick={() => onQuestionEdit(question)}>
                          <EditIcon/>
                        </IconButton>
                        )}
                        {isFunction(onDelete) && (
                        <IconButton size="small" className={classes.iconButton} onClick={() => onDelete({ id: question.id, name: question.title }, `processdomains/${processDomain.id}/subdomains/${subDomain.id}/questions`)}>
                          <DeleteOutlineIcon/>
                        </IconButton>
                        )}
                      </div>
                    </div>
                )}
                >
                  <TreeItem key={`${question.id}${question.level0}`} nodeId={`${question.id}${question.level0}`} label={`0: ${question.level0}`}/>
                  <TreeItem key={`${question.id}${question.level1}`} nodeId={`${question.id}${question.level1}`} label={`1: ${question.level1}`}/>
                  <TreeItem key={`${question.id}${question.level2}`} nodeId={`${question.id}${question.level2}`} label={`2: ${question.level2}`}/>
                  <TreeItem key={`${question.id}${question.level3}`} nodeId={`${question.id}${question.level3}`} label={`3: ${question.level3}`}/>
                  <TreeItem key={`${question.id}${question.level4}`} nodeId={`${question.id}${question.level4}`} label={`4: ${question.level4}`}/>
                  <TreeItem key={`${question.id}${question.level5}`} nodeId={`${question.id}${question.level5}`} label={`5: ${question.level5}`}/>
                </TreeItem>
              ))}
              {addOption && <TreeItem nodeId={`questionAdd ${subDomain.id}`} label="+ Frage hinzufügen" onLabelClick={() => addQuestion(processDomain.id, subDomain.id)}/>}
            </TreeItem>
          ))}
          {addOption && <TreeItem nodeId={`subdomainAdd ${processDomain.id}`} label="+ Unterdomäne hinzufügen" onLabelClick={() => addSubdomain(processDomain.id)}/>}
        </TreeItem>
      ))}
      {addOption && <TreeItem nodeId="proccessdomainAdd" label="+ Prozessdomäne hinzufügen" onLabelClick={() => addProcessDomain()}/>}

    </TreeView>
  )
}

MaturityModelTree.propTypes = {
  tree: PropTypes.object,
  onProcessDomainEdit: PropTypes.func,
  onSubDomainEdit: PropTypes.func,
  onQuestionEdit: PropTypes.func,
  onDelete: PropTypes.func,
  addOption: PropTypes.bool,
  onQuestionWeightEdit: PropTypes.func,
  onQuestionAnswer: PropTypes.func,
  itSystems: PropTypes.bool,
  onSystemsEdit: PropTypes.func
}

export default MaturityModelTree

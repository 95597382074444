import { colors } from '@material-ui/core'

const white = '#FFFFFF'
const black = '#000000'

export default {
  black,
  white,
  primary: {
    main: '#1A6E58',
    light: '#B4D6CE',
    dark: '#345656',
    contrastText: white,
  },
  secondary: {
    main: '#2f414b',
    light: '#58676F',
    dark: '#25343C',
    contrastText: white,
  },
  success: {
    contrastText: white,
    dark: colors.green[900],
    main: colors.green[600],
    light: colors.green[400],
  },
  info: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue[600],
    light: colors.blue[400],
  },
  warning: {
    contrastText: white,
    dark: colors.orange[900],
    main: colors.orange[600],
    light: colors.orange[400],
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400],
  },
  text: {
    primary: '#5e676c',
    secondary: '#5e676c',
    link: colors.blue[600],
  },
  background: {
    dark: '#F4F6F8',
    default: '#F4F6F8',
    paper: white,
  },
  graph: {
    first: '#B4D6CE',
    second: '#6BA99C',
    third: '#1A6E58',
    fourth: '#256365',
    fifth: '#345656',
  },
  icon: colors.blueGrey[600],
  divider: colors.grey[200],
}

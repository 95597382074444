import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import HelpIcon from '@material-ui/icons/HelpOutline';
import Popover from '@material-ui/core/Popover'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton'

const ReactMarkdown = require('react-markdown')

const useStyles = makeStyles((theme) => ({
  root: {},
  popoverButton: {
    marginRight: theme.spacing(1)
  },
  typography: {
    maxWidth: 800,
    minWidth: 300,
    padding: theme.spacing(2),
  },
  headerText: {
    padding: theme.spacing(2),
  },
  helpHeader: {
    paddingBottom: 0,
  }
}))

const QuickHelpButton = ({ helpText, helpTitle }) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div className={classes.root}>
      <IconButton size="small" onClick={handleClick}>
        <HelpIcon/>
      </IconButton>
      <Popover
        className={classes.popover}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Grid className={classes.helpHeader} size={12} container justify="space-between" direction="row" alignItems="center">
          <Typography className={classes.headerText} variant="h3">
            {helpTitle}
          </Typography>
          <IconButton onClick={handleClose} className={classes.popoverButton}>
            <CloseIcon/>
          </IconButton>
        </Grid>
        <ReactMarkdown source={helpText} className={classes.typography}/>
      </Popover>
    </div>
  )
}

QuickHelpButton.propTypes = {
  helpText: PropTypes.string,
  helpTitle: PropTypes.string,
}

export default QuickHelpButton

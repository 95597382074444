import React from 'react'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import { Button } from '@material-ui/core'
import PropTypes from 'prop-types'
import { withAuth } from '../auth'

const useStyles = makeStyles(() => ({
  root: {},
}))

const SignOutButton = ({ children, className, auth: { logout, signedIn } }) => {
  const classes = useStyles()

  const handleSignOut = () => {
    logout()
  }

  return (
    <div>
      {signedIn && (
      <div>
        <Button
          className={clsx(classes.root, className)}
          onClick={handleSignOut}>
          {children}
        </Button>
      </div>
      )}
    </div>
  )
}

SignOutButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  auth: PropTypes.any
}

export default withAuth(SignOutButton)

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import Paper from '@material-ui/core/Paper'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import { IconButton, Typography } from '@material-ui/core'
import { Link, useParams } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { apiClientInstance } from '../../../apiClient'
import DialogDelete from '../../../components/DialogDelete'
import Header from '../../../components/Header'
import SubmitFab from '../../../components/SubmitFab'
import TableFooter from '../../../components/TableFooter'
import { getClientSitesApi, getOneClientApi } from './apiConstants'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(5),
    right: theme.spacing(5),
  },
  tableCell: {
    borderBottomColor: theme.palette.divider
  },
  tableFooter: {
    marginRight: theme.spacing(8),
  },
  placeholder: {
    height: theme.spacing(10)
  }
}))

const ClientsList = ({ className }) => {
  const { id } = useParams()
  const classes = useStyles()

  const [sites, setSites] = useState([])
  const [allItemsCount, setAllItemsCount] = useState(0)
  const [totalPages, setTotalPages] = useState(0)
  const [dialogOpen, setDialogOpen] = useState(false);
  const [toDeleteEntry, setToDeleteEntry] = useState({});
  const { enqueueSnackbar } = useSnackbar()
  const [name, setName] = useState('')

  const fetchData = (params) => {
    apiClientInstance.get(getOneClientApi(id))
      .then((response) => {
        setName(response.data.name)
      }).catch((error) => {
        enqueueSnackbar(`Fehler beim Laden: ${error}`, {
          variant: 'error',
        })
      })
    apiClientInstance.get(getClientSitesApi(id), params)
      .then((response) => {
        setTotalPages(response.data.totalPages)
        setSites(response.data.content)
        setAllItemsCount(response.data.totalElements)
      }).catch((error) => {
        enqueueSnackbar(`Fehler beim Laden: ${error}`, {
          variant: 'error',
        })
      })
  }

  const handleDeleteDialogClose = () => {
    setDialogOpen(false);
  };

  const handleDelete = () => {
    const url = `${getClientSitesApi(id)}/${toDeleteEntry.id}`
    apiClientInstance.delete(url)
      .then(() => {
        handleDeleteDialogClose()
        fetchData()
        enqueueSnackbar(` ${toDeleteEntry.name} erfolgreich gelöscht!`, {
          variant: 'success',
        })
      }).catch((error) => {
        enqueueSnackbar(`Fehler beim Löschen: ${error}`, {
          variant: 'error',
        })
        handleDeleteDialogClose()
      })
  }

  const handleDialogOpen = (entry) => {
    setToDeleteEntry(entry)
    setDialogOpen(true);
  };

  return (
    <div className={clsx(classes.root, className)}>
      <Header title={`Betriebsstätten von ${name}`} />
      <DialogDelete
        title={toDeleteEntry.name}
        handleClose={handleDeleteDialogClose}
        handleDelete={handleDelete}
        dialogText="Wenn die Betriebsstätte gelöscht wird werden auch alle Status der Betriebsstätte gelöscht"
        open={dialogOpen}/>
      <Paper>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableCell} style={{ width: '35%' }}>
                  <h3>
                    Name
                  </h3>
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <h3>
                    Abkürzung
                  </h3>
                </TableCell>
                <TableCell className={classes.tableCell} style={{ width: '13%' }}/>
              </TableRow>
            </TableHead>
            <TableBody className={classes.root}>
              {sites && sites.map((entry) => (
                <TableRow key={entry.id}>
                  <TableCell className={classes.tableCell}>
                    <Typography>
                      {entry.name}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Typography>
                      {entry.shortName}
                    </Typography>
                  </TableCell>
                  <TableCell align="right" className={classes.tableCell}>
                    <IconButton onClick={() => handleDialogOpen(entry)}>
                      <DeleteOutlineIcon/>
                    </IconButton>
                    <IconButton component={Link} to={`${entry.id}`}>
                      <EditIcon/>
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              {!(sites.length > 0) && (
                <TableRow>
                  <TableCell width="100%">
                    <Typography align="center">
                      Keine Betriebsstätten gefunden
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TableFooter
          allItemsCount={allItemsCount}
          fetchData={(params) => fetchData(params)}
          totalPages={totalPages}
        />
        <SubmitFab show component={Link} to="sites/new" text="Hinzufügen">
          <AddIcon/>
        </SubmitFab>
      </Paper>
      <div className={classes.placeholder}/>
    </div>
  )
}

ClientsList.propTypes = {
  className: PropTypes.string,
}

export default ClientsList

import React from 'react'
import PropTypes from 'prop-types'
import { Card, CardContent, MuiThemeProvider } from '@material-ui/core'
import Divider from '@material-ui/core/Divider'
import Form from '@rjsf/material-ui'
// eslint-disable-next-line camelcase
import localize_de from 'ajv-i18n/localize/de/index'
import { makeStyles } from '@material-ui/styles'
import SubmitFab from '../SubmitFab'
import theme from '../../theme'
import SwitchCheckbox from './widgets/SwitchCheckBox'

/**
 * localize the validations errors. Using ajv-i18n here. In a later release when
 * schema-forms supports localization this code might be removed
 */
const transformErrors = (errors) => {
  console.log('transformErrors', errors)
  const translatedErrors = errors.map((e) => {
    // add keyword field which is used in localize function
    e.keyword = e.name
    return e;
  })
  localize_de(translatedErrors)
  return translatedErrors

}

const useStyles = makeStyles(() => ({
  placeholder: {
    height: theme.spacing(10)
  }
}))

/**
 * re-usable from to edit entities, The form structure and layout are defined using json-schema
 */
const EditForm = ({ schema, uiSchema, formData, onSubmit }) => {
  // text field outlines variant https://github.com/rjsf-team/react-jsonschema-form/issues/1987
  const classes = useStyles()
  const muiTheme = { ...theme }
  muiTheme.props = {
    MuiTextField: {
      variant: 'outlined',
    },
  }

  const widgets = {
    CheckboxWidget: SwitchCheckbox,
  }

  const log = (type) => console.log.bind(console, type)
  const submit = (data) => {
    onSubmit(data.formData, data.errors)
  }

  return (
    <div>
      <Card>
        <Divider/>
        <CardContent>
          <MuiThemeProvider theme={muiTheme}>
            <Form
              schema={schema}
              uiSchema={uiSchema}
              formData={formData}
              onChange={log('changed')}
              onSubmit={submit}
              onError={log('errors')}
              noHtml5Validate
              widgets={widgets}
              transformErrors={transformErrors}
              showErrorList={false}

          >
              <SubmitFab show/>
            </Form>
          </MuiThemeProvider>
        </CardContent>
      </Card>
      <div className={classes.placeholder}/>
    </div>
  )
}

EditForm.propTypes = {
  schema: PropTypes.object.isRequired,
  formData: PropTypes.object,
  uiSchema: PropTypes.object,
  onSubmit: PropTypes.func,
}

export default EditForm

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import Paper from '@material-ui/core/Paper'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import { IconButton, Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import AddIcon from '@material-ui/icons/Add'
import ApartmentIcon from '@material-ui/icons/Apartment'
import EditIcon from '@material-ui/icons/Edit'
import AssessmentIcon from '@material-ui/icons/Assessment'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import PeopleIcon from '@material-ui/icons/People'
import { apiClientInstance } from '../../../apiClient'
import DialogDelete from '../../../components/DialogDelete'
import Header from '../../../components/Header'
import SubmitFab from '../../../components/SubmitFab'
import TableFooter from '../../../components/TableFooter'
import { baseClientsApi, getOneClientApi } from './apiConstants'

const helpText = `
## Mandanten (Träger)

Hier werden die Madanten verwaltet.
Die Liste zeigt alle Mandanten

### Name
Der Name des Trägers

### Aktionen
- Nutzer zuordnen: zuständige Berater oder Mitarbeiter zuordnen
- Reifegradmodelle: Reifegradmodell zuweisen
- Betriebsstätten: Betriebsstätten eines Mandanten verwalten
- Träger-Daten bearbeiten
- Löschen
`

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(5),
    right: theme.spacing(5),
  },
  tableCell: {
    borderBottomColor: theme.palette.divider,
  },
  tableFooter: {
    marginRight: theme.spacing(8),
  },
  placeholder: {
    height: theme.spacing(10)
  }
}))

const ClientsList = ({ className }) => {
  const classes = useStyles()

  const [clients, setClients] = useState([])
  const [allItemsCount, setAllItemsCount] = useState(0)
  const [totalPages, setTotalPages] = useState(0)
  const [dialogOpen, setDialogOpen] = React.useState(false)
  const [currentSelectedEntry, setCurrentSelectedEntry] = React.useState({})
  const { enqueueSnackbar } = useSnackbar()

  const fetchData = (params) => {
    apiClientInstance.get(baseClientsApi, params)
      .then((response) => {
        setClients(response.data.content)
        setAllItemsCount(response.data.totalElements)
        setTotalPages(response.data.totalPages)
      })
      .catch((error) => {
        enqueueSnackbar(`Fehler beim Laden: ${error}`, {
          variant: 'error',
        })
      })
  }

  const handleDeleteDialogClose = () => {
    setDialogOpen(false)
  }

  const handleDelete = () => {
    const url = getOneClientApi(currentSelectedEntry.id)
    apiClientInstance.delete(url)
      .then(() => {
        handleDeleteDialogClose()
        fetchData()
        enqueueSnackbar(` ${currentSelectedEntry.name} erfolgreich gelöscht!`, {
          variant: 'success',
        })
      })
      .catch((error) => {
        enqueueSnackbar(`Fehler beim Löschen: ${error}`, {
          variant: 'error',
        })
        handleDeleteDialogClose()
      })
  }

  const handleDialogOpen = (entry) => {
    setCurrentSelectedEntry(entry)
    setDialogOpen(true)
  }

  return (
    <div className={clsx(classes.root, className)}>
      <Header
        title="Mandanten"
        helpTitle="Mandanten"
        helpText={helpText}/>
      <DialogDelete
        title={currentSelectedEntry.name}
        handleClose={handleDeleteDialogClose}
        dialogText="Wenn dieser Mandant gelöscht wird, werden auch alle Verknüpfungen zu Reifegradmodellen gelöscht."
        handleDelete={handleDelete}
        open={dialogOpen}/>
      <Paper>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableCell}>
                  <h3>
                    Name
                  </h3>
                </TableCell>
                <TableCell className={classes.tableCell}/>
                <TableCell className={classes.tableCell}/>
              </TableRow>
            </TableHead>
            <TableBody className={classes.root}>
              {clients && clients.map((entry) => (
                <TableRow key={entry.id}>
                  <TableCell className={classes.tableCell}>
                    <Typography>
                      {entry.name}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}/>
                  <TableCell align="right" className={classes.tableCell}>
                    <IconButton component={Link} to={`${entry.id}/users`}>
                      <PeopleIcon/>
                    </IconButton>
                    <IconButton component={Link} to={`${entry.id}/maturitymodels`}>
                      <AssessmentIcon/>
                    </IconButton>
                    <IconButton component={Link} to={`${entry.id}`}>
                      <ApartmentIcon/>
                    </IconButton>
                    <IconButton component={Link} to={`${entry.id}/edit`}>
                      <EditIcon/>
                    </IconButton>
                    <IconButton onClick={() => handleDialogOpen(entry)}>
                      <DeleteOutlineIcon/>
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              {!(clients.length > 0)
                && (
                <TableRow>
                  <TableCell width="100%">
                    <Typography align="center">
                      Keine Mandanten gefunden
                    </Typography>
                  </TableCell>
                </TableRow>
                )}
            </TableBody>
          </Table>
        </TableContainer>
        <TableFooter
          allItemsCount={allItemsCount}
          fetchData={(params) => fetchData(params)}
          totalPages={totalPages}
        />
        <SubmitFab show component={Link} to="new" text="Hinzufügen">
          <AddIcon/>
        </SubmitFab>
      </Paper>
      <div className={classes.placeholder}/>
    </div>
  )
}

ClientsList.propTypes = {
  className: PropTypes.string,
}

export default ClientsList

import React from 'react'
import { useSnackbar } from 'notistack'
import { useLocation, useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'
import EditForm from '../../../components/crud/EditForm'
import { apiClientInstance } from '../../../apiClient'
import editSchema from './schema-site.json'
import Header from '../../../components/Header'
import { getClientSitesApi } from './apiConstants'

const NewSite = () => {

  const { id } = useParams()

  // id is not part of new object
  const schema = { ...editSchema }
  delete schema.properties.id

  const { state } = useLocation()
  let data

  if (state) {
    data = {
      name: state.data.name,
      address: state.data.address,
      contact: state.data.contact,
    }
  }

  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  const save = (formData) => {
    const urlbase = getClientSitesApi(id)
    apiClientInstance.post(urlbase, formData)
      .then(() => {
        enqueueSnackbar('Gespeichert', {
          variant: 'success',
        })
        navigate('../../')
      })
      .catch((error) => {
        console.log(error)
        enqueueSnackbar(`Fehler beim Speichern: ${error}`, {
          variant: 'error',
        })
      })
  }

  return (
    <div>
      <Header title="Neue Betriebsstätte"/>
      <EditForm
        schema={schema}
        formData={data}
        onSubmit={save}
      />
    </div>
  )
}

export default NewSite

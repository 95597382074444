import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import { useSnackbar } from 'notistack'
import { apiClientInstance } from '../../../apiClient'
import Header from '../../../components/Header'
import { baseSettingsApi } from './apiConstants'
import EditForm from '../../../components/crud/EditForm'
import schema from './schema.json'

const useStyles = makeStyles((theme) => ({
  root: {},
  fab: {
    position: 'fixed',
    bottom: theme.spacing(5),
    right: theme.spacing(5),
  },
  tableCell: {
    borderBottomColor: theme.palette.divider
  },
  tableFooter: {
    marginRight: theme.spacing(8),
  }
}))

const SettingsDisplay = ({ className }) => {
  const classes = useStyles()

  const [settings, setSettings] = useState()
  const { enqueueSnackbar } = useSnackbar()

  const fetchData = () => {
    apiClientInstance.get(baseSettingsApi)
      .then((response) => {
        setSettings(response.data)
      }).catch((error) => {
        enqueueSnackbar(`Fehler beim Laden: ${error}`, {
          variant: 'error',
        })
      })

  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const save = (formData) => {
    apiClientInstance.put(baseSettingsApi, formData)
      .then(() => {
        enqueueSnackbar('Erfolgreich gespeichert', {
          variant: 'success',
        })
      }).catch((error) => {
        enqueueSnackbar(`Fehler beim Speichern: ${error}`, {
          variant: 'error',
        })
      })
  }

  return (
    <div className={clsx(classes.root, className)}>
      <Header title="Systemeinstellungen"/>
      <EditForm
        schema={schema}
        formData={settings}
        onSubmit={save}
       />
    </div>
  )
}

SettingsDisplay.propTypes = {
  className: PropTypes.string,
}

export default SettingsDisplay

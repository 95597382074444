export const baseClientsApi = 'admin/clients/'

export function getOneClientApi (id) {
  return `${baseClientsApi}${id}`
}

export function getOneClientModelsApi (id) {
  return `${baseClientsApi}${id}/maturitymodels/`
}

export function getClientSitesApi (id) {
  return `${baseClientsApi}${id}/sites/`
}

export function getClientUsersApi (id) {
  return `${baseClientsApi}${id}/users/`
}

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import Paper from '@material-ui/core/Paper'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import { IconButton, Typography } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import AddIcon from '@material-ui/icons/Add'
import { Link } from 'react-router-dom'
import moment from 'moment'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import Checkbox from '@material-ui/core/Checkbox';
import RefreshIcon from '@material-ui/icons/Refresh';
import { adminQueryInstance, apiClientInstance } from '../../../apiClient'
import SubmitFab from '../../../components/SubmitFab'
import Header from '../../../components/Header'
import DialogDelete from '../../../components/DialogDelete'

const helpText = `
## Nutzerverwaltung

Hier können neue Nutzer angelegt werden, dafür wird eine E-Mailadresse und eine Nutzergruppe benötigt.

Ein neu angelegter Nutzer erhält eine Begrüßungs-Email mit Initial-Kennwort und kann sich anmelden. 

### Administrator

Darf alles, auch neue Nutzer anlegen

### Berater

Ein Berater darf die Verwaltungsanwendung nutzen und ist für bestimmte Mandanten zuständig. 

### Mitarbeiter eines Mandanten

Krankenhausmanager, IT-Leiter oder Ähnliches, darf für einen oder mehrere bestimmte Mandanten (Träger) Daten erfassen und auswerten.
Hat keinen Zugriff auf die Verwaltungsanwendung.
`

const useStyles = makeStyles((theme) => ({
  root: {},
  fab: {
    position: 'fixed',
    bottom: theme.spacing(5),
    right: theme.spacing(5),
  },
  tableCell: {
    borderBottomColor: theme.palette.divider,
  },
  placeholder: {
    height: theme.spacing(10)
  }
}))

const UsersList = ({ className }) => {
  const classes = useStyles()

  const [users, setUsers] = useState([])
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [toDeleteEntry, setToDeleteEntry] = React.useState({});
  const { enqueueSnackbar } = useSnackbar()

  const fetchData = () => {
    adminQueryInstance.get('users')
      .then((response) => {
        setUsers(response.data.Users)
      })
      .catch((error) => {
        enqueueSnackbar(`Fehler beim Laden: ${error}`, {
          variant: 'error',
        })
        console.log(error)
      })

  }

  const handleDialogOpen = (entry) => {
    setToDeleteEntry(entry)
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleDelete = () => {
    const url = `users/${toDeleteEntry.Username}`
    adminQueryInstance.post(`${url}/disable`)
      .then(() => {
        adminQueryInstance.delete(url).then(() => {
          handleDialogClose()
          fetchData()
          enqueueSnackbar('Erfolgreich gelöscht!', {
            variant: 'success',
          })
        })
      }).catch((error) => {
        enqueueSnackbar(`Fehler beim Löschen: ${error}`, {
          variant: 'error',
        })
        handleDialogClose()
      })
    apiClientInstance.delete(`admin/users/${toDeleteEntry.Username}`)
      .catch((error) => {
        enqueueSnackbar(`Fehler beim Löschen: ${error}`, {
          variant: 'error',
        })
      })
  }

  const onNewPassword = (email) => {
    adminQueryInstance.post(`users/${email}/reactivate`)
      .then(() => {
        fetchData()
        enqueueSnackbar('Neues Passwort wurde als E-Mail versendet', {
          variant: 'success',
        })
      })
      .catch((error) => {
        enqueueSnackbar(`Fehler beim Aktivieren: ${error}`, {
          variant: 'error',
        })
      })
  }

  const onEnabledChange = (id, event) => {
    const url = `users/${id}`
    if (event.target.checked) {
      adminQueryInstance.post(`${url}/enable`)
        .then(() => {
          fetchData()
        })
        .catch((error) => {
          enqueueSnackbar(`Fehler beim Aktivieren: ${error}`, {
            variant: 'error',
          })
        })
    } else {
      adminQueryInstance.post(`${url}/disable`)
        .then(() => {
          fetchData()
        })
        .catch((error) => {
          enqueueSnackbar(`Fehler beim Deaktivieren: ${error}`, {
            variant: 'error',
          })
        })
    }
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const knownUserStatus = {
    FORCE_CHANGE_PASSWORD: 'Initialkennwort ändern',
    CONFIRMED: 'Bestätigt',
  }

  function translateUserStatus (userStatus) {
    return knownUserStatus[userStatus] || userStatus
  }

  const getEmail = (user) => {
    const email = user.Attributes.find((attribute) => attribute.Name === 'email')
    return email.Value
  }

  return (
    <div className={clsx(classes.root, className)}>
      <Header
        title="Nutzerverwaltung"
        helpTitle="Nutzerverwaltung"
        helpText={helpText}
      />
      <DialogDelete
        title={toDeleteEntry.Attributes ? getEmail(toDeleteEntry) : 'dieser Nutzer'}
        handleClose={handleDialogClose}
        handleDelete={handleDelete}
        open={dialogOpen}/>
      <Paper>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableCell} style={{ width: '20%' }}>
                  <h3>
                    Nutzer
                  </h3>
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <h3>
                    Status
                  </h3>
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <h3>
                    Geändert
                  </h3>
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <h3>
                    Aktiviert
                  </h3>
                </TableCell>
                <TableCell className={classes.tableCell} style={{ width: '13%' }}/>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((entry) => (
                <TableRow key={entry.Username}>
                  <TableCell className={classes.tableCell}>
                    <Typography>
                      {getEmail(entry)}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Typography>
                      {translateUserStatus(entry.UserStatus)}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Typography>
                      {moment(entry.UserLastModifiedDate)
                        .format('DD.MM.YY HH:mm')}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Checkbox
                      checked={entry.Enabled}
                      onChange={(e) => onEnabledChange(entry.Username, e)}
                    />
                  </TableCell>
                  <TableCell align="right" className={classes.tableCell}>
                    {entry.UserStatus === 'FORCE_CHANGE_PASSWORD' && (
                      <IconButton onClick={() => onNewPassword(getEmail(entry))}>
                        <RefreshIcon/>
                      </IconButton>
                    )}
                    <IconButton onClick={() => handleDialogOpen(entry)}>
                      <DeleteOutlineIcon/>
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              {!(users.length > 0) && (
              <TableRow>
                <TableCell width="100%">
                  <Typography align="center">
                    Keine Nutzer gefunden
                  </Typography>
                </TableCell>
              </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <SubmitFab show component={Link} to="new" text="Hinzufügen">
          <AddIcon/>
        </SubmitFab>
      </Paper>
      <div className={classes.placeholder}/>
    </div>
  )
}

UsersList.propTypes = {
  className: PropTypes.string,
}

export default UsersList

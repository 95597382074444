import React from 'react'
import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router'
import EditForm from '../../../components/crud/EditForm'
import { apiClientInstance } from '../../../apiClient'
import editSchema from './schema.json'
import uiSchema from './uiSchema.json'
import Header from '../../../components/Header'
import { baseFieldsOfActionApi } from './apiConstants'

const NewActionField = () => {

  // id is not part of new object
  const schema = { ...editSchema }
  delete schema.properties.id

  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const data = {}
  const save = async (formData) => {
    try {
      const response = await apiClientInstance.post(baseFieldsOfActionApi, formData)
      console.log('saved data: ', response.data)
      enqueueSnackbar('Gespeichert', {
        variant: 'success',
      })
      navigate(-1)
    } catch (error) {
      console.log(error)
      enqueueSnackbar(`Fehler beim Speichern: ${error}`, {
        variant: 'error',
      })
    }
  }

  return (
    <div>
      <Header title="Neues Handlungsfeld"/>
      <EditForm
        schema={schema}
        uiSchema={uiSchema}
        formData={data}
        onSubmit={save}
    />
    </div>
  )
}

export default NewActionField

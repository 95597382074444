const schema = (standards, sites) => {
  return {
    definitions: {},
    $schema: 'http://json-schema.org/draft-07/schema#',
    $id: 'https://example.com/object1614778781.json',
    title: 'IT System erstellen',
    type: 'object',
    required: [
      'productName',
    ],
    properties: {
      productName: {
        $id: '#root/productName',
        title: 'Name',
        type: 'string',
        default: '',
        examples: [
          'string'
        ],
        minLength: 2
      },
      manufacturer: {
        $id: '#root/manufacturer',
        title: 'Hersteller',
        type: 'string',
        default: '',
        examples: [
          'string'
        ],
        minLength: 2
      },
      supportedStandards: {
        $id: '#root/supportedStandards',
        title: 'Interoperabilitätstandards',
        type: 'array',
        items: {
          type: 'string',
          enum: standards,
        },
      },
      description: {
        $id: '#root/description',
        title: 'Beschreibung',
        type: ['string', 'null'],
        default: '',
        examples: [
          'string'
        ]
      },
      clientSiteIds: {
        $id: '#root/clientSiteIds',
        title: 'Betriebsstätten',
        type: 'array',
        items: {
          type: 'object',
          properties: {
            name: {
              type: 'string',
              title: 'Name',
              enum: sites
            },
          },
        },
        minItems: 1,
        uniqueItems: true,
      }
    }
  }
}

export default schema

import React from 'react'
import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router'
import EditForm from '../../../components/crud/EditForm'
import { adminQueryInstance } from '../../../apiClient'
import editSchema from './schema.json'
import Header from '../../../components/Header'

// show multi-line textfield
const uiSchema = {
  description: {
    'ui:widget': 'textarea',
    'ui:options': {
      rows: 3,
    },
  },
}

const NewUser = () => {

  // id is not part of new object
  const schema = { ...editSchema }
  delete schema.properties.id

  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const data = { groups: ['admin'] }
  const save = (formData) => {
    const url = 'users'
    adminQueryInstance.post(url, formData)
      .then(() => {
        enqueueSnackbar('Nutzer angelegt. Eine Bestätigungs-E-Mail wurde gesendet', {
          variant: 'success',
        })
        navigate(-1)
      })
      .catch((error) => {
        enqueueSnackbar(`Fehler beim Anlegen: ${error}`, {
          variant: 'error',
        })
      })
  }

  return (
    <div>
      <Header title="Neuen Nutzer anlegen"/>
      <EditForm
        schema={schema}
        uiSchema={uiSchema}
        formData={data}
        onSubmit={save}
    />
    </div>
  )
}

export default NewUser

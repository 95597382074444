export const baseMaturityModelApi = 'admin/maturitymodels/'

export function getOneModelApi (id) {
  return `${baseMaturityModelApi}${id}`
}

export function getModelProcessDomainApi (id) {
  return `${getOneModelApi(id)}/processdomains/`
}

export function getModelSubDomainApi (id, processDomainId) {
  return `${getModelProcessDomainApi(id)}${processDomainId}/subdomains/`
}

export function getModelQuestionByDomainApi (id, processDomainId, subDomainId) {
  return `${getModelSubDomainApi(id, processDomainId)}${subDomainId}/questions/`
}

export function getModelQuestionApi (id) {
  return `${getOneModelApi(id)}/questions/`
}

export function getModelImportApi (id) {
  return `${getOneModelApi(id)}/import`
}

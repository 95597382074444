import React from 'react'
import { makeStyles } from '@material-ui/core'
import Page from 'src/components/Page'
import AuthGuard from '../../../components/AuthGuard'

// create json schema https://extendsclass.com/json-schema-validator.html
// import schema from './schema-spruch.json'
// import schema from './schema-rgm.json'
// import schema from './schema-rgm-frage.json'
// import formData from './data-rgm-frage.json'
// import schema from './schema-customer.json'
// import schema from './schema-client.json'

import schema from './schema-import-rgm.json'

import EditForm from '../../../components/crud/EditForm'

// const formData = {}
import formData from './data-import-rgm.json'

// example for customer to use custoemr checkbox
const uiSchema = {
  processDomains: {
    'ui:options': {
      addable: false,
    },
  },
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
}))

const FormPage = () => {
  const classes = useStyles()
  return (
    <Page
      className={classes.root}
      title="Form">
      <AuthGuard>
        <EditForm
          title="Beispiel-Formular"
          schema={schema}
          uiSchema={uiSchema}
          formData={formData}
        />
      </AuthGuard>
    </Page>
  )
}

export default FormPage

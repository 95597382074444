import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import Paper from '@material-ui/core/Paper'
import { useSnackbar } from 'notistack'
import { Button, DialogContentText, Divider, IconButton, makeStyles, Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'
import EditIcon from '@material-ui/icons/Edit'
import moment from 'moment'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Header from '../../../components/Header'
import MaturityModelTree from '../../../components/MaturityModelTree'
import QuestionDialog from '../../../components/QuestionDialog'
import { apiClientInstance } from '../../../apiClient'
import { getClientItSystemsApi, getClientModelGoals, getClientModelsApi, getClientModelStatusAnswerApi, getClientModelStatusApi, getSubDomainItSystemsApi } from './apiConstants'
import RadarChart from '../../../components/charts/RadarChart'
import { calculateStatusRadarChartData } from './statusCalculations'
import ItSystemDialog from '../../../components/ItSystemDialog'

const useStyles = makeStyles((theme) => ({
  root: {},
  subheader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  commentHeader: {
    display: 'flex',
    alignItems: 'flex-start',
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
}))

const MaturityModelStatus = () => {
  const classes = useStyles()

  // id is not part of new object
  const [totalQuestions, setTotalQuestions] = useState(0)
  const [statusInfo, setStatusInfo] = useState({})
  const [released, setReleased] = useState()
  const [treeData, setTreeData] = useState({})
  const [goalsData, setGoalsData] = useState({})
  const [chartData, setChartData] = useState({})
  const { clientId, siteId, id, statusId } = useParams()
  const [open, setOpen] = useState(false)
  const [currentQuestion, setCurrentQuestion] = useState({})
  const [currentProcessDomain, setCurrentProcessDomain] = useState('')
  const [currentSubDomain, setCurrentSubDomain] = useState('')
  const [answeredQuestions, setAnsweredQuestions] = useState(0)
  const [title, setTitle] = useState('')
  const [systemSubDomain, setSystemSubdomain] = useState({})
  const [assignedSystems, setAssignedSystems] = useState([])
  const [remainingSystems, setRemainingSystems] = useState([])
  const [systemsDialogOpen, setSystemsDialogOpen] = useState(false)
  const [completionDialogOpen, setCompletionDialogOpen] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const onQuestionClick = (question, processDomain, subDomain, event) => {
    if (event) {
      event.preventDefault()
    }
    question.currentAnswer = statusInfo.answers[question.id]?.level
    question.comment = statusInfo.answers[question.id]?.comment
    setCurrentQuestion(question)
    setCurrentProcessDomain(processDomain)
    setCurrentSubDomain(subDomain)
    setOpen(true)
  }

  const handleCancel = () => {
    setOpen(false)
  }

  const fetchData = () => {
    apiClientInstance.get(getClientModelsApi(clientId) + id)
      .then((response) => {
        setTitle(response.data.name)
        setReleased(response.data.maturityModel.released)

        apiClientInstance.get(getClientModelStatusApi(clientId, siteId, id) + statusId)
          .then((r) => {
            setStatusInfo(r.data)
            let counter = 0
            const { maturityModel } = response.data
            maturityModel.processDomains.forEach((processDomain) => {
              processDomain.subDomains.forEach((subDomain) => {
                // subDomain.systems = r.data.systems[subDomain.id]
                subDomain.questions.forEach((question) => {
                  counter++
                  question.count = counter
                  question.currentAnswer = r.data.answers[question.id]?.level
                })
              })
            })
            setAnsweredQuestions(Object.keys(r.data.answers).length)
            setTreeData(maturityModel)
            setTotalQuestions(counter)

          })
          .catch((error) => {
            enqueueSnackbar(`Fehler beim Laden: ${error}`, {
              variant: 'error',
            })
          })
      })
      .catch((error) => {
        enqueueSnackbar(`Fehler beim Laden: ${error}`, {
          variant: 'error',
        })
      })
  }

  const save = (question) => {
    if ((question.currentAnswer >= -1
      && question.currentAnswer <= 5)
      || question.currentAnswer === 0) {
      apiClientInstance.put(getClientModelStatusAnswerApi(
        clientId, siteId, id, statusId, question.id,
      ), { comment: question.comment, level: question.currentAnswer })
        .then(() => {
          enqueueSnackbar('Speichern erfolgreich', {
            variant: 'success',
          })
          fetchData()
        })
    } else {
      apiClientInstance.delete(getClientModelStatusAnswerApi(
        clientId, siteId, id, statusId, question.id
      ))
        .then(() => {
          enqueueSnackbar('Speichern erfolgreich', {
            variant: 'success',
          })
          fetchData()
        })
    }
  }

  const nextQuestion = (question) => {
    handleCancel()
    let found = false
    treeData.processDomains.forEach((processDomain) => {
      processDomain.subDomains.forEach((subDomain) => {
        const idx = subDomain.questions
          .indexOf(subDomain.questions.find((entry) => entry.id === question.id))
        if (idx >= 0 && subDomain.questions.length > idx + 1) {
          onQuestionClick(subDomain.questions[idx + 1], processDomain.name, subDomain.name)
          found = true
        }
      })
      if (!found) {
        const idx = processDomain.subDomains.indexOf(processDomain.subDomains
          .find((domain) => domain.name === currentSubDomain))
        if (idx >= 0 && processDomain.subDomains.length > idx + 1
          && processDomain.name === currentProcessDomain) {
          const newSubDomain = processDomain.subDomains[idx + 1]
          onQuestionClick(newSubDomain.questions[0], processDomain.name, newSubDomain.name)
          found = true
        }
      }
    })
    if (!found) {
      const idx = treeData.processDomains.indexOf(treeData.processDomains
        .find((domain) => domain.name === currentProcessDomain))
      if (idx >= 0 && treeData.processDomains.length > idx + 1) {
        const newProcessDomain = treeData.processDomains[idx + 1]
        onQuestionClick(newProcessDomain.subDomains[0].questions[0],
          newProcessDomain.name, newProcessDomain.subDomains[0].name)
        found = true
      } else {
        handleCancel()
      }
    }
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    apiClientInstance.get(getClientModelGoals(clientId, siteId, id))
      .then((r) => {
        setGoalsData(r.data)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * calculate the chart data
   */
  useEffect(() => {
    const hasData = treeData?.id && statusInfo?.answers && goalsData?.questionGoalMap
    if (!hasData) return

    setChartData(
      calculateStatusRadarChartData(
        treeData,
        statusInfo.answers,
        goalsData.questionGoalMap
      ),
    )

  }, [treeData, statusInfo, goalsData])

  const onSystemClick = (subDomain) => {
    setSystemSubdomain(subDomain)
    apiClientInstance.get(getSubDomainItSystemsApi(clientId, siteId, id, statusId, subDomain.id))
      .then((response) => {
        setAssignedSystems(response.data.content)
        apiClientInstance.get(getClientItSystemsApi(clientId))
          .then((r) => {
            setRemainingSystems(r.data.content
              .filter((entry) => ((!response.data.content
                .find((system) => system.itSystemId === entry.itSystemId))
                && (entry.clientSiteIds.length === 0
                  || entry.clientSiteIds.includes(siteId)))))
            setSystemsDialogOpen(true)
          })
      })
  }

  const saveSystems = (systems) => {
    const array = { itSystemIds: [] }
    systems.forEach((system) => array.itSystemIds.push(system.itSystemId))
    apiClientInstance.put(
      getSubDomainItSystemsApi(clientId, siteId, id, statusId, systemSubDomain.id), array
    )
      .then(() => {
        setSystemsDialogOpen(false)
      })
      .catch((error) => {
        enqueueSnackbar(`Fehler beim Speichern: ${error}`, {
          variant: 'error',
        })
        setSystemsDialogOpen(false)
      })
  }

  const saveStatus = (status) => {
    apiClientInstance.put(getClientModelStatusApi(clientId, siteId, id) + statusId, status)
      .then(() => {
        setCompletionDialogOpen(false)
      })

  }

  const openFirstQuestion = () => {
    const processDomain = treeData.processDomains[0]
    const subDomain = processDomain.subDomains[0]
    const question = subDomain.questions[0]
    onQuestionClick(question, processDomain.name, subDomain.name)
  }

  return (
    <div>
      <Header title={`Status: ${title}`}>
        <div>
          {!statusInfo.completed && (
            <div>
              <Button variant="contained" color="primary" onClick={() => openFirstQuestion()}>
                Erfassen
              </Button>
              <Button color="primary" onClick={() => setCompletionDialogOpen(true)}>
                Abschließen
              </Button>
            </div>
          )}
          {statusInfo.completed && (
            <div>
              <Button
                color="primary"
                onClick={() => {
                  enqueueSnackbar('Status ist bereits abgeschlossen und kann nicht mehr verändert werden', {
                    variant: 'warning',
                  })
                }}>
                Abgeschlossen
              </Button>
            </div>
          )}
        </div>
      </Header>
      <Dialog open={completionDialogOpen}>
        <DialogTitle>
          Status abschließen
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Wenn sie den Status abschließen kann dieser nicht wieder bearbeitet werden.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              const x = statusInfo
              x.completed = true
              saveStatus(x)
            }}>
            Bestätigen
          </Button>
          <Button color="primary" onClick={() => setCompletionDialogOpen(false)}>
            Abbrechen
          </Button>
        </DialogActions>
      </Dialog>
      {released && !statusInfo.completed
        ? (
          <QuestionDialog
            question={currentQuestion}
            processDomain={currentProcessDomain}
            subDomain={currentSubDomain}
            open={open}
            onOpen={onQuestionClick}
            onClose={handleCancel}
            onSave={(question) => save(question)}
            onNext={(question) => nextQuestion(question)}
            totalQuestions={totalQuestions}
          />
        )
        : (
          <QuestionDialog
            question={currentQuestion}
            processDomain={currentProcessDomain}
            subDomain={currentSubDomain}
            open={open}
            onOpen={onQuestionClick}
            onClose={handleCancel}
            onNext={(question) => nextQuestion(question)}
            totalQuestions={totalQuestions}
          />
        )}
      <ItSystemDialog
        subDomain={systemSubDomain}
        systems={assignedSystems}
        notChosenSystems={remainingSystems}
        open={systemsDialogOpen}
        onClose={() => setSystemsDialogOpen(false)}
        onSave={(systems) => saveSystems(systems)}
        />
      <Paper>
        {Object.entries(statusInfo).length > 0
        && (
          <div>
            <div className={classes.subheader}>
              <Typography>
                Erfasst von:
                {' '}
                {statusInfo.author}
              </Typography>
              <Typography>
                Abteilung:
                {' '}
                {statusInfo.department}
              </Typography>
              <Typography>
                Fragen beantwortet:
                {' '}
                {`${answeredQuestions}/${totalQuestions}`}
              </Typography>
              <Typography>
                Erstellt am:
                {' '}
                {moment(statusInfo.date).format('DD.MM.YYYY')}
              </Typography>
              <Typography>
                Zuletzt bearbeitet:
                {' '}
                {moment(statusInfo.lastUpdateTime)
                  .format('DD.MM.YYYY hh:mm')}
              </Typography>
              {!statusInfo.completed
              && (
              <IconButton component={Link} to="edit" size="small">
                <EditIcon/>
              </IconButton>
              )}
            </div>
            {statusInfo.comment && statusInfo.comment.length > 0 && (
              <div>
                <Divider/>
                <Typography className={classes.commentHeader}>
                  {statusInfo.comment}
                </Typography>
              </div>
            )}
            <Divider/>
          </div>
        )}
        <RadarChart chartData={chartData}/>
        <MaturityModelTree
          tree={treeData}
          onQuestionAnswer={(question, processDomain, subDomain, event) => {
            onQuestionClick(question, processDomain, subDomain, event)
          }}
          itSystems
          onSystemsEdit={(subDomain) => {
            onSystemClick(subDomain)
          }}
        />
      </Paper>
    </div>
  )
}

MaturityModelStatus.propTypes = {}

export default MaturityModelStatus

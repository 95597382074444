import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import Paper from '@material-ui/core/Paper'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import { Typography } from '@material-ui/core'
import { Navigate, useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { apiClientInstance } from '../../../apiClient'
import Header from '../../../components/Header'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  tableCell: {
    borderBottomColor: theme.palette.divider
  },
  errorMessage: {
    padding: theme.spacing(2),
  }
}))

const ClientViewList = ({ className }) => {
  const classes = useStyles()
  const navigate = useNavigate();

  const [clients, setClients] = useState([])
  const { enqueueSnackbar } = useSnackbar()

  const fetchData = () => {
    apiClientInstance.get('clients', { size: 1000 })
      .then((response) => {
        setClients(response.data)
      }).catch((error) => {
        enqueueSnackbar(`Fehler beim Laden: ${error}`, {
          variant: 'error',
        })
      })
  }

  const rerouteClientView = (url) => {
    navigate(url)
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={clsx(classes.root, className)}>
      <Header title="Mandant auswählen"/>
      <Paper>
        {clients && clients.length > 1
        && (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableCell} style={{ width: '20%' }}>
                    <h3>
                      Name
                    </h3>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody className={classes.root}>
                {clients.map((entry) => (
                  <TableRow
                    hover
                    key={entry.id}
                    onClick={() => rerouteClientView(`/../app/client/${entry.id}/dashboard`)}
                  >
                    <TableCell className={classes.tableCell}>
                      <Typography>
                        {entry.name}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {clients && clients.length === 1 && (
          <Navigate to={`/app/client/${clients[0].id}/dashboard`}/>
        )}
        {clients && clients.length === 0
        && (
          <Typography
            variant="h5"
            className={classes.errorMessage}>
            Sie sind keinem Mandanten zugeordnet
          </Typography>
        )}
      </Paper>
    </div>
  )
}

ClientViewList.propTypes = {
  className: PropTypes.string,
}

export default ClientViewList

import React from 'react'
import { Container, makeStyles } from '@material-ui/core'
import Page from 'src/components/Page'
import { Route, Routes } from 'react-router-dom'
import AuthGuard from '../../../components/AuthGuard'
import UsersList from './UsersList'
import NewUser from './NewUser'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}))

const Users = () => {
  const classes = useStyles()

  return (
    <Page
      className={classes.root}
      title="Nutzer">
      <AuthGuard requiredRole={['admin', 'consultant']}>
        <Container>
          <Routes>
            <Route name="users" path="/" element={<UsersList/>}/>
            <Route path="/?page=:page" element={<UsersList/>}/>
            <Route path="new" element={<NewUser/>}/>
          </Routes>
        </Container>
      </AuthGuard>
    </Page>
  )
}

export default Users

import React from 'react'
import { Container, Grid, makeStyles } from '@material-ui/core'
import Page from 'src/components/Page'
import { Route, Routes } from 'react-router-dom'
import AuthGuard from '../../../components/AuthGuard'
import ClientsMaturityModelsDisplay from './ClientsMaturityModelsDisplay'
import ClientsMaturityModelsRatings from './ClientsMaturityModelsRatings'
import NewModelStatus from './NewModelStatus'
import MaturityModelStatus from './MaturityModelStatus'
import EditModelStatus from './EditModelStatus'
import ModelStatusList from './ModelStatusList'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}))

const ClientsMaturityModels = () => {
  const classes = useStyles()

  return (
    <Page
      className={classes.root}
      title="Betriebsstätten">
      <AuthGuard requiredRole={['admin', 'consultant', 'client']}>
        <Container maxWidth={false}>
          <Grid
            container>
            <Grid item xs={12}>
              <Routes>
                <Route name="maturitymodel" path="/" element={<ClientsMaturityModelsDisplay/>}/>
                <Route path=":id" element={<ClientsMaturityModelsRatings/>}/>
                <Route path=":id/status/new" element={<NewModelStatus/>}/>
                <Route path=":id/status" element={<MaturityModelStatus/>}/>
                <Route path=":id/status/history" element={<ModelStatusList/>}/>
                <Route path=":id/status/:statusId" element={<MaturityModelStatus/>}/>
                <Route path=":id/status/:statusId/edit" element={<EditModelStatus/>}/>
              </Routes>
            </Grid>
          </Grid>
        </Container>
      </AuthGuard>
    </Page>
  )
}

export default ClientsMaturityModels

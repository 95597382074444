import React from 'react'
import { Container, Grid, makeStyles } from '@material-ui/core'
import Page from 'src/components/Page'
import { Route, Routes } from 'react-router-dom'
import AuthGuard from '../../../components/AuthGuard'
import ClientSettingsPage from './ClientSettingsPage'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}))

const ClientView = () => {
  const classes = useStyles()

  return (
    <Page
      className={classes.root}
      title="Nutzereinstellungen">
      <AuthGuard>
        <Container maxWidth={false}>
          <Grid
            container>
            <Grid item xs={12}>
              <Routes>
                <Route name="clientSettings" path="/" element={<ClientSettingsPage/>}/>
              </Routes>
            </Grid>
          </Grid>
        </Container>
      </AuthGuard>
    </Page>
  )
}

export default ClientView

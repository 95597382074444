import React, { useEffect, useState } from 'react'
import validate from 'validate.js'
import { makeStyles } from '@material-ui/styles'
import { Button, CardMedia, Grid, Link, TextField, Typography } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Navigate } from 'react-router'
import { withAuth } from '../../auth'
import Page from '../../components/Page'

const schema = {
  username: {
    presence: { allowEmpty: false, message: '^Nutzername wird benötigt' },
    length: {
      maximum: 64,
    },
  },
  password: {
    presence: { allowEmpty: false, message: '^Passwort wird benötigt' },
    length: {
      maximum: 128,
    },
  },
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%',
  },
  grid: {
    height: '100%',
  },
  quoteContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    height: 'calc(100vh - 64px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  quoteInner: {
    textAlign: 'center',
    flexBasis: '600px',
  },
  quoteText: {
    color: theme.palette.white,
    fontWeight: 300,
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white,
  },
  content: {
    height: 'calc(100vh - 64px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  logoImage: {
    marginLeft: theme.spacing(4),
  },
  contentBody: {
    height: '100%',
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    },
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  title: {
    marginTop: theme.spacing(3),
  },
  sugestion: {
    marginTop: theme.spacing(2),
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  signInButton: {
    margin: theme.spacing(2, 0),
  },
}))

const SignIn = ({ auth:
  { error: authError, login, signedIn, hasRequiredRole, newPasswordRequired }
}) => {

  const classes = useStyles()
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  })

  useEffect(() => {
    const errors = validate(formState.values, schema)

    // eslint-disable-next-line no-shadow
    setFormState((formState) => ({
      ...formState,
      isValid: !errors,
      errors: authError ? { username: [authError.message], password: [authError.message] }
        : errors || {},
    }))
  }, [formState.values, authError])

  const handleChange = (event) => {
    event.persist()

    // eslint-disable-next-line no-shadow
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }))
  }

  const handleSignIn = (event) => {
    event.preventDefault()
    const { values: { username, password } } = formState
    login(username.trim(), password)
  }

  const hasError = (field) => !!((formState.touched[field] && formState.errors[field]) || authError || formState.values[field] === '')

  return (
    <Page title="Sign-In">
      <div className={classes.root}>
        {newPasswordRequired && <Navigate to="/app/admin/clientView"/>}
        {signedIn && hasRequiredRole(['admin', 'consultant']) && <Navigate to="/app/admin/dashboard"/>}
        {signedIn && hasRequiredRole(['client']) && <Navigate to="/app/admin/clientView"/>}
        <Grid
          className={classes.grid}
          container>
          <Grid
            className={classes.quoteContainer}
            item
            lg={6}>
            <CardMedia
              className={classes.quote}
              image="/static/images/Grafik.png"
              title="Grafik"
              >
              <Typography
                className={classes.quoteText}
                variant="h1">
                {/* Enter Title */}
              </Typography>
            </CardMedia>
          </Grid>
          <Grid
            className={classes.content}
            item
            lg={6}
            xs={12}>
            <div className={classes.content}>
              <div className={classes.contentBody}>
                <form
                  className={classes.form}
                  onSubmit={handleSignIn}>
                  <Typography
                    className={classes.title}
                    variant="h2">
                    Anmelden
                  </Typography>
                  <Typography
                    className={classes.sugestion}
                    color="textSecondary"
                    variant="body1">
                    Melden Sie sich mit Ihrem Nutzernamen an
                  </Typography>
                  <TextField
                    className={classes.textField}
                    error={hasError('username')}
                    autoComplete="username"
                    fullWidth
                    helperText={
                    hasError('username') ? formState.errors.username?.[0] : null
                  }
                    label="Nutzername"
                    name="username"
                    onChange={handleChange}
                    type="text"
                    value={formState.values.username || ''}
                    variant="outlined"/>
                  <TextField
                    className={classes.textField}
                    error={hasError('password')}
                    autoComplete="current-password"
                    fullWidth
                    helperText={
                    hasError('password') ? formState.errors.password?.[0] : null
                  }
                    label="Passwort"
                    name="password"
                    onChange={handleChange}
                    type="password"
                    value={formState.values.password || ''}
                    variant="outlined"/>
                  <Typography
                    className={classes.sugestion}
                    color="textSecondary"
                    variant="body1">
                    <Link component={RouterLink} to="/reset-password">
                      Passwort vergessen?
                    </Link>
                  </Typography>
                  <Button
                    className={classes.signInButton}
                    color="primary"
                    disabled={!formState.isValid}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained">
                    Anmelden
                  </Button>
                  <Typography variant="body1">
                    &copy;
                    {' '}
                    <Link
                      component="a"
                      href="https://www.siobra.de/"
                      target="_blank">
                      Siobra
                    </Link>
                    . 2021
                  </Typography>
                </form>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </Page>
  )
}

SignIn.propTypes = {
  auth: PropTypes.any
}

export default withAuth(SignIn)

import React, { useEffect, useState } from 'react'
import { useSnackbar } from 'notistack'
import { useLocation, useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'
import EditForm from '../../../components/crud/EditForm'
import { apiClientInstance } from '../../../apiClient'
import editSchema from './subDomainSchema.json'
import Header from '../../../components/Header'
import { getModelSubDomainApi } from './apiConstants'

// show multi-line textfield
const uiSchema = {
  description: {
    'ui:widget': 'textarea',
    'ui:options': {
      rows: 3,
    },
  },
}

const EditSubDomain = () => {

  // id is not part of new object
  const schema = { ...editSchema }
  delete schema.properties.id
  const { id, domainId } = useParams()
  const [data, setData] = useState({})
  const { state } = useLocation()
  const { processDomainId } = state

  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const save = (formData) => {
    const url = `${getModelSubDomainApi(id, processDomainId)}${domainId}`
    apiClientInstance.put(url, formData)
      .then(() => {
        enqueueSnackbar('Domäne bearbeitet', {
          variant: 'success',
        })
        navigate('../../../')
      })
      .catch((error) => {
        enqueueSnackbar(`Fehler beim Bearbeiten: ${error}`, {
          variant: 'error',
        })
      })
  }

  const fetchData = () => {
    apiClientInstance.get(`${getModelSubDomainApi(id, processDomainId)}${domainId}`)
      .then((response) => {
        setData({
          modelId: id,
          id: domainId,
          name: response.data.name,
        })
      }).catch((error) => {
        enqueueSnackbar(`Fehler beim Laden: ${error}`, {
          variant: 'error',
        })
      })
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <Header title="Unterdomäne bearbeiten"/>
      <EditForm
        schema={schema}
        uiSchema={uiSchema}
        formData={data}
        onSubmit={save}
      />
    </div>
  )
}
EditSubDomain.propTypes = {
}

export default EditSubDomain

/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_F767XKBAE",
    "aws_user_pools_web_client_id": "6o6v7qlq08u9o61s5u7gl813oi",
    "oauth": {}
};


export default awsmobile;

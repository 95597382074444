import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Box, Divider, Drawer, Hidden, List, makeStyles, Typography } from '@material-ui/core'
import AppsIcon from '@material-ui/icons/Apps';
import ViewCompactIcon from '@material-ui/icons/ViewCompact';
import Link from '@material-ui/core/Link'
import PeopleIcon from '@material-ui/icons/People';
import AssessmentIcon from '@material-ui/icons/Assessment';
import BusinessIcon from '@material-ui/icons/Business';
import SettingsIcon from '@material-ui/icons/Settings';
import NavItem from './NavItem'
import { withAuth } from '../../../auth'

const items = [
  {
    href: '/app/admin/maturitymodel',
    icon: AssessmentIcon,
    title: 'Reifegradmodelle',
  },
  {
    href: '/app/admin/fieldsofaction',
    icon: AppsIcon,
    title: 'Handlungsfelder',
  },
  {
    href: '/app/admin/clients',
    icon: BusinessIcon,
    title: 'Mandanten',
  },
]

const adminItems = [
  {
    href: '/app/admin/users',
    icon: PeopleIcon,
    title: 'Nutzerverwaltung',
  },
  {
    href: '/app/admin/settings',
    icon: SettingsIcon,
    title: 'Systemeinstellungen'
  }
]

const viewItems = [
  {
    href: '/app/admin/clientview',
    icon: ViewCompactIcon,
    title: 'Mandanten-Ansicht',
  },
]

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)',
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64,
  },
}))

const NavBar = ({ onMobileClose, openMobile, auth: { hasRequiredRole } }) => {
  const classes = useStyles()
  const location = useLocation()

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column">

      {hasRequiredRole(['admin', 'consultant']) && (
      <Box p={2}>
        <List>
          {items.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
        </List>
      </Box>
      )}
      <Divider/>

      {hasRequiredRole(['admin']) && (
        <Box p={2}>
          <List>
            <Typography variant="body1">
              Admin
            </Typography>
            {adminItems.map((item) => (
              <NavItem
                href={item.href}
                key={item.title}
                title={item.title}
                icon={item.icon}
              />
            ))}
          </List>
        </Box>
      )}
      <Divider/>
      <Box p={2}>
        <List>
          <Typography variant="body1">
            Ansicht
          </Typography>
          {viewItems.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
        </List>
      </Box>
      <Box flexGrow={1}/>
      <Box display="flex" pl={2} pb={1}>
        <Typography variant="body1">
          Made by
          {' '}
          <Link
            component="a"
            href="https://www.siobra.de/"
            target="_blank">
            Siobra
          </Link>
          {' '}
          . 2021
        </Typography>
      </Box>
    </Box>
  )

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary">
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent">
          {content}
        </Drawer>
      </Hidden>
    </>
  )
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
  auth: PropTypes.object
}

NavBar.defaultProps = {
  onMobileClose: () => {
  },
  openMobile: false,
}

export default withAuth(NavBar)

import React from 'react'
import PropTypes from 'prop-types'
import { Navigate } from 'react-router-dom';
import { withAuth } from '../auth'

// eslint-disable-next-line react/prefer-stateless-function
class AuthGuard extends React.Component {

  render() {
    const { children, requiredRole, auth } = this.props
    const { signedIn, authenticating, newPasswordRequired = true,
      hasRequiredRole } = auth
    if (newPasswordRequired) {
      return <Navigate to="/new-password"/>
    }
    if (signedIn && !authenticating && hasRequiredRole(requiredRole)) {
      return (
        <>
          {children}
        </>
      );
    }
    if (!authenticating) {
      return <Navigate to="/sign-in"/>;
    }
    return <></>

  }
}

AuthGuard.propTypes = {
  children: PropTypes.any,
  requiredRole: PropTypes.any,
  auth: PropTypes.any,
  signedIn: PropTypes.bool,
  authenticating: PropTypes.bool,
  newPasswordRequired: PropTypes.bool,
  hasRequiredRole: PropTypes.func
}

export default withAuth(AuthGuard);

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { apiClientInstance } from '../../../apiClient'
import { getClientModelGoals, getClientModelsApi, getClientModelStatusApi } from './apiConstants'
import { calculateStatusRadarChartData } from './statusCalculations'
import DisplayCard from '../../../components/DisplayCard'

const ClientsMaturityModelsDisplayCard = ({ entry, siteId }) => {
  const navigate = useNavigate()
  const [goalsData, setGoalsData] = useState([])
  const [answeredQuestions, setAnsweredQuestions] = useState([])
  const [treeData, setTreeData] = useState([])
  const [innerData, setInnerData] = useState({})

  const redirectModel = (id) => {
    navigate(`${id}`)
  }

  const newStatus = (id) => {
    navigate(`${id}/status/new`)
  }

  const editStatus = (model) => {
    if (model.currentStatusId) {
      navigate(`${entry.modelId}/status/${model.currentStatusId}`)
    }
  }

  const statusHistory = (id) => {
    navigate(`${id}/status/history`)
  }

  useEffect(() => {
    if (entry.currentStatusId) {
      // get Goals for Status
      apiClientInstance.get(getClientModelGoals(entry.clientId, siteId, entry.modelId))
        .then((r) => {
          setGoalsData(r.data)
        })
      // get Model Tree
      apiClientInstance.get(getClientModelsApi(entry.clientId) + entry.modelId)
        .then((response) => {
          const { maturityModel } = response.data
          setTreeData(maturityModel)
        })
      // get already answered questions
      apiClientInstance.get(getClientModelStatusApi(entry.clientId, siteId, entry.modelId)
        + entry.currentStatusId)
        .then((r) => {
          setAnsweredQuestions(r.data.answers)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entry])

  /**
   * calculate the chart data
   */
  useEffect(() => {
    const hasData = treeData?.id && answeredQuestions && goalsData?.questionGoalMap
    if (!hasData) return

    setInnerData(
      calculateStatusRadarChartData(
        treeData,
        answeredQuestions,
        goalsData.questionGoalMap
      ),
    )

  }, [treeData, answeredQuestions, goalsData])

  return (
    <DisplayCard
      chartData={innerData}
      name={`${entry.name} ${entry.version}`}
      released={entry.maturityModel.released}
      lastUpdated={entry.lastStatusUpdate}
      onRatingsClick={() => redirectModel(entry.modelId)}
      onNewClick={() => newStatus(entry.modelId)}
      onHistoryClick={() => statusHistory(entry.modelId)}
      onEditClick={() => editStatus(entry)}
      entry={entry}
    />
  )
}

ClientsMaturityModelsDisplayCard.propTypes = {
  entry: PropTypes.object,
  siteId: PropTypes.string
}

export default ClientsMaturityModelsDisplayCard

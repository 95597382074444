import React from 'react'
import moment from 'moment'
import { useNavigate, useParams } from 'react-router'
import { useSnackbar } from 'notistack'
import EditForm from '../../../components/crud/EditForm'
import editSchema from './statusSchema.json'
import Header from '../../../components/Header'
import { apiClientInstance } from '../../../apiClient'
import { getClientModelStatusApi } from './apiConstants'

// show multi-line textfield
const uiSchema = {
  comment: {
    'ui:widget': 'textarea',
    'ui:options': {
      rows: 3,
    },
  },
}

const NewModelStatus = () => {

  // id is not part of new object
  const schema = { ...editSchema }
  delete schema.properties.id
  const navigate = useNavigate()
  const { clientId, siteId, id } = useParams()
  const data = { date: moment().format('YYYY-MM-DD') }
  const { enqueueSnackbar } = useSnackbar()

  const save = (formData) => {
    apiClientInstance.post(getClientModelStatusApi(clientId, siteId, id), formData)
      .then((response) => {
        navigate(`../${response.data.statusId}`)
        enqueueSnackbar('Status angelegt. Der Status kann nun bearbeitet werden', {
          variant: 'success',
        })
      })
      .catch((error) => {
        enqueueSnackbar(`Fehler beim Anlegen: ${error}`, {
          variant: 'error',
        })
      })
  }

  return (
    <div>
      <Header title="Neuen Status erstellen"/>
      <EditForm
        schema={schema}
        uiSchema={uiSchema}
        formData={data}
        onSubmit={save}
      />
    </div>
  )
}
NewModelStatus.propTypes = {
}

export default NewModelStatus

import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import EditForm from '../../../components/crud/EditForm'
import { apiClientInstance } from '../../../apiClient'
import schema from './jsonSchemaBuilder'
import Header from '../../../components/Header'
import { baseSettingsApi } from '../../adminView/settings/apiConstants'
import { getClientSitesApi, getClientSystemsApi } from './apiConstants'

// show multi-line textfield
const uiSchema = {
  description: {
    'ui:widget': 'textarea',
    'ui:options': {
      rows: 3,
    },
  },
  clientSiteIds: {
    'ui:options': {
    }
  }
}

const NewItSystem = () => {
  const { clientId } = useParams()
  const navigate = useNavigate()
  const [standards, setStandards] = useState([])
  const [sites, setSites] = useState([])
  const [siteNames, setSiteNames] = useState([])
  const data = {}

  const save = (formData) => {
    if (formData.clientSiteIds.find((entry) => entry.name === 'Alle')) {
      formData.clientSiteIds = []
    } else {
      const names = formData.clientSiteIds
      const ids = []
      names.forEach((entry) => ids.push(sites.find((site) => site.name === entry.name)?.id))
      formData.clientSiteIds = ids
    }
    apiClientInstance.put(getClientSystemsApi(clientId), formData)
      .then(() => {
        navigate(-1)
      })
  }

  const fetchData = () => {
    apiClientInstance.get(baseSettingsApi)
      .then((response) => {
        const list = []
        response.data.interoperabilityStandards.forEach((standard) => list.push(standard.name))
        setStandards(list)
      })
    apiClientInstance.get(getClientSitesApi(clientId))
      .then((response) => {
        setSites(response.data.content)
        const names = ['Alle']
        response.data.content.forEach((entry) => names.push(entry.name))
        setSiteNames(names)
      })
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <Header title="Neues System anlegen"/>
      <EditForm
        schema={schema(standards, siteNames)}
        uiSchema={uiSchema}
        formData={data}
        onSubmit={save}
      />
    </div>
  )
}

export default NewItSystem

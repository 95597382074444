import React, { useState } from 'react'
import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router'
import useTheme from '@material-ui/core/styles/useTheme'
import { useMediaQuery } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import { baseClientsApi } from './apiConstants'
import Header from '../../../components/Header'
import editSchema from './schema-client.json'
import { apiClientInstance } from '../../../apiClient'
import EditForm from '../../../components/crud/EditForm'

const urlbase = baseClientsApi

const NewClient = () => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [dialogData, setDialogData] = useState({})

  // id is not part of new object
  const schema = { ...editSchema }
  delete schema.properties.id

  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const data = {}

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleYesClick = () => {
    handleClose()
    navigate(`../${dialogData.id}/sites/new`, { state: { data: dialogData.data } })
  }

  const handleNoClick = () => {
    handleClose()
    navigate(-1)
  }
  const save = (formData) => {
    apiClientInstance.post(urlbase, formData)
      .then((response) => {
        enqueueSnackbar(`${response.data.name} wurde angelegt`, {
          variant: 'success',
        })
        setDialogData({ id: response.data.id, data: formData })
        if (!formData.hasSeveralSites) {
          handleClickOpen()
        } else {
          navigate(`../${response.data.id}`)
        }
      })
      .catch((error) => {
        enqueueSnackbar(`Fehler beim Speichern: ${error}`, {
          variant: 'error',
        })
      })
  }

  return (
    <div>
      <Header title="Neuer Mandant"/>
      <EditForm
        schema={schema}
        formData={data}
        onSubmit={save}
      />

      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">Neue Betriebsstätte anlegen?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Sie haben einen Mandanten mit nur einer Betriebsstätte erstellt.
            Wollen sie diese sofort anlegen?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleNoClick} color="primary">
            Nein
          </Button>
          <Button onClick={handleYesClick} color="primary" autoFocus>
            Ja
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default NewClient

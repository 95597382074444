import React from 'react'
import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router'
import PropTypes from 'prop-types'
import EditForm from '../../../components/crud/EditForm'
import { apiClientInstance } from '../../../apiClient'
import editSchema from './schema.json'
import Header from '../../../components/Header'
import { withAuth } from '../../../auth'
import { baseMaturityModelApi } from './apiConstants'

// show multi-line textfield
const uiSchema = {
  description: {
    'ui:widget': 'textarea',
    'ui:options': {
      rows: 3,
    },
  },
}

const NewMaturityModel = ({ auth: { user } }) => {

  // id is not part of new object
  const schema = { ...editSchema }
  delete schema.properties.id
  const { email } = user.attributes
  const data = { released: false, author: email }

  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const save = (formData) => {
    apiClientInstance.post(baseMaturityModelApi, formData)
      .then((response) => {
        enqueueSnackbar('Reifemodell erstellt', {
          variant: 'success',
        })
        navigate(`../${response.data.id}`)
      })
      .catch((error) => {
        enqueueSnackbar(`Fehler beim Anlegen: ${error}`, {
          variant: 'error',
        })
      })
  }

  return (
    <div>
      <Header title="Neues Reifegradmodell erstellen"/>
      <EditForm
        schema={schema}
        uiSchema={uiSchema}
        formData={data}
        onSubmit={save}
      />
    </div>
  )
}
NewMaturityModel.propTypes = {
  auth: PropTypes.object,
  user: PropTypes.object
}

export default withAuth(NewMaturityModel)

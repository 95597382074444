import React, { useState } from 'react'
import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'
import EditForm from '../../../components/crud/EditForm'
import { apiClientInstance } from '../../../apiClient'
import editSchema from './processDomainSchema.json'
import Header from '../../../components/Header'
import { getModelProcessDomainApi } from './apiConstants'

// show multi-line textfield
const uiSchema = {
  description: {
    'ui:widget': 'textarea',
    'ui:options': {
      rows: 3,
    },
  },
}

const AddProcessDomain = () => {
  // id is not part of new object
  const schema = { ...editSchema }
  delete schema.properties.id
  const { id } = useParams()
  const [data] = useState({})

  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const save = (formData) => {
    const url = getModelProcessDomainApi(id)
    apiClientInstance.post(url, formData)
      .then(() => {
        enqueueSnackbar('Prozessdomäne gespeichert', {
          variant: 'success',
        })
        navigate('../../')
      })
      .catch((error) => {
        enqueueSnackbar(`Fehler beim Erstellen: ${error}`, {
          variant: 'error',
        })
      })
  }

  return (
    <div>
      <Header title="Prozessdomäne erstellen"/>
      <EditForm
        schema={schema}
        uiSchema={uiSchema}
        formData={data}
        onSubmit={save}
      />
    </div>
  )
}
AddProcessDomain.propTypes = {
}

export default AddProcessDomain

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import Paper from '@material-ui/core/Paper'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import { IconButton, Typography } from '@material-ui/core'
import { useParams } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import AddIcon from '@material-ui/icons/Add'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import DialogContent from '@material-ui/core/DialogContent'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { adminQueryInstance, apiClientInstance } from '../../../apiClient'
import DialogDelete from '../../../components/DialogDelete'
import Header from '../../../components/Header'
import SubmitFab from '../../../components/SubmitFab'
import { getClientUsersApi, getOneClientApi } from './apiConstants'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(5),
    right: theme.spacing(5),
  },
  tableCell: {
    borderBottomColor: theme.palette.divider
  },
  tableFooter: {
    marginRight: theme.spacing(8),
  },
  select: {
    width: '100%'
  },
  placeholder: {
    height: theme.spacing(10)
  }
}))

const ClientsUsers = ({ className }) => {
  const classes = useStyles()

  const [name, setName] = useState('')
  const [users, setUsers] = useState([])
  const [allUsers, setAllUsers] = useState([])
  const [selectedUser, setSelectedUser] = useState({})
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const [userDialogOpen, setUserDialogOpen] = React.useState(false);
  const [currentSelectedEntry, setCurrentSelectedEntry] = React.useState({});
  const { enqueueSnackbar } = useSnackbar()
  const { id } = useParams()

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const fetchData = () => {
    apiClientInstance.get(getClientUsersApi(id), { size: 50 })
      .then((response) => {
        setUsers(response.data.content)
      }).catch((error) => {
        enqueueSnackbar(`Fehler beim Laden: ${error}`, {
          variant: 'error',
        })
      })
    adminQueryInstance.get('users')
      .then((response) => {
        setAllUsers(response.data.Users)
        setSelectedUser(response.data.Users[0])
      }).catch((error) => {
        enqueueSnackbar(`Fehler beim Laden: ${error}`, {
          variant: 'error',
        })
      })
    apiClientInstance.get(getOneClientApi(id))
      .then((response) => {
        setName(response.data.name)
      }).catch((error) => {
        enqueueSnackbar(`Fehler beim Laden: ${error}`, {
          variant: 'error',
        })
      })
  }

  const getEmail = (user) => {
    if (user) {
      const email = user.Attributes.find((attribute) => attribute.Name === 'email')
      return email.Value
    }
    return undefined
  }

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
  };

  const handleDelete = () => {
    const url = getClientUsersApi(id)
    apiClientInstance.delete(url + currentSelectedEntry.userId)
      .then(() => {
        handleDeleteDialogClose()
        fetchData()
        enqueueSnackbar(` ${currentSelectedEntry.email} erfolgreich gelöscht!`, {
          variant: 'success',
        })
      }).catch((error) => {
        enqueueSnackbar(`Fehler beim Löschen: ${error}`, {
          variant: 'error',
        })
        handleDeleteDialogClose()
      })
  }

  const handleDialogOpen = (entry) => {
    setCurrentSelectedEntry(entry)
    setDeleteDialogOpen(true);
  };

  const findIdInUsers = (userId) => {
    return users.find((user) => user.userId === userId)
  }

  const handleModelDialogOpen = () => {
    setUserDialogOpen(true);
    const newAllUsers = allUsers.filter((entry) => !(findIdInUsers(entry.Username)))
    setAllUsers(newAllUsers)
  };

  const handleModelDialogClose = () => {
    setUserDialogOpen(false);
  };

  const handleSelectChange = (event) => {
    setSelectedUser(event.target.value)
  }

  const handleAdd = () => {
    const url = getClientUsersApi(id)
    const email = getEmail(selectedUser)
    const payload = {
      userId: selectedUser.Username,
      email
    }
    apiClientInstance.post(url, payload)
      .then(() => {
        handleModelDialogClose()
        fetchData()
        enqueueSnackbar(` ${email} erfolgreich hinzugefügt!`, {
          variant: 'success',
        })
      }).catch((error) => {
        enqueueSnackbar(`Fehler beim Hinzufügen: ${error}`, {
          variant: 'error',
        })
        handleModelDialogClose()
      })
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={clsx(classes.root, className)}>
      <Header title={`Nutzer für Mandanten ${name}`}/>
      <DialogDelete
        title={currentSelectedEntry.email}
        handleClose={handleDeleteDialogClose}
        dialogText=""
        handleDelete={handleDelete}
        open={deleteDialogOpen}/>
      <Dialog
        open={userDialogOpen}
        className={classes.dialog}
        fullScreen={fullScreen}
        maxWidth="xs"
        fullWidth>
        <DialogTitle id="user_dialog">
          Nutzer hinzufügen
        </DialogTitle>
        <DialogContent>
          {allUsers.length > 0
          && (
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedUser}
            onChange={handleSelectChange}
            className={classes.select}
            defaultValue=""
          >
            {allUsers.map((user) => (
              <MenuItem value={user} key={user.Username}>{getEmail(user)}</MenuItem>
            ))}
          </Select>
          )}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleModelDialogClose} color="primary">
            Abbrechen
          </Button>
          <Button
            onClick={handleAdd}
            color="primary"
            autoFocus>
            Bestätigen
          </Button>
        </DialogActions>
      </Dialog>
      <Paper>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableCell} style={{ width: '20%' }}>
                  <h3>
                    Name
                  </h3>
                </TableCell>
                <TableCell className={classes.tableCell}/>
                <TableCell className={classes.tableCell} style={{ width: '13%' }}/>
              </TableRow>
            </TableHead>
            <TableBody className={classes.root}>
              {users && users.map((entry) => (
                <TableRow key={entry.userId}>
                  <TableCell className={classes.tableCell}>
                    <Typography>
                      {entry.email}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}/>
                  <TableCell align="right" className={classes.tableCell}>
                    <IconButton onClick={() => handleDialogOpen(entry)}>
                      <DeleteOutlineIcon/>
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              {!(users.length > 0) && (
                <TableRow>
                  <TableCell width="100%">
                    <Typography align="center">
                      Keine zugewiesenen Nutzer gefunden
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <SubmitFab show onClick={handleModelDialogOpen} text="Hinzufügen">
          <AddIcon/>
        </SubmitFab>
      </Paper>
      <div className={classes.placeholder}/>
    </div>
  )
}

ClientsUsers.propTypes = {
  className: PropTypes.string,
}

export default ClientsUsers

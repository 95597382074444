import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { useSnackbar } from 'notistack'
import moment from 'moment'
import EditForm from '../../../components/crud/EditForm'
import editSchema from './statusSchema.json'
import Header from '../../../components/Header'
import { apiClientInstance } from '../../../apiClient'
import { getClientModelStatusApi } from './apiConstants'

// show multi-line textfield
const uiSchema = {
  comment: {
    'ui:widget': 'textarea',
    'ui:options': {
      rows: 3,
    },
  },
}

const EditModelStatus = () => {

  // id is not part of new object
  const schema = { ...editSchema }
  delete schema.properties.id
  const navigate = useNavigate()
  const { clientId, siteId, id, statusId } = useParams()
  const [data, setData] = useState({})
  const { enqueueSnackbar } = useSnackbar()

  const fetchData = () => {
    apiClientInstance.get(getClientModelStatusApi(clientId, siteId, id) + statusId)
      .then((response) => {
        setData({
          date: moment(response.data.date).format('YYYY-MM-DD'),
          author: response.data.author,
          comment: response.data.comment,
          department: response.data.department
        })
      })
      .catch((error) => {
        enqueueSnackbar(`Fehler beim Laden: ${error}`, {
          variant: 'error',
        })
      })
  }

  const save = (formData) => {
    apiClientInstance.put(getClientModelStatusApi(clientId, siteId, id) + statusId, formData)
      .then(() => {
        navigate('..')
        enqueueSnackbar('Status gespeichert.', {
          variant: 'success',
        })
      })
      .catch((error) => {
        enqueueSnackbar(`Fehler beim Anlegen: ${error}`, {
          variant: 'error',
        })
      })
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <Header title="Status bearbeiten"/>
      <EditForm
        schema={schema}
        uiSchema={uiSchema}
        formData={data}
        onSubmit={save}
      />
    </div>
  )
}
EditModelStatus.propTypes = {
}

export default EditModelStatus

import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import validate from 'validate.js'
import { makeStyles } from '@material-ui/styles'
import { TextField } from 'formik-material-ui'
import { Field, Form, Formik } from 'formik'
import { Typography } from '@material-ui/core'
import SubmitFab from './SubmitFab'
import { VALIDATION_SCHEMA_EMAIL, VALIDATION_SCHEMA_PASSWORD } from '../constants'

const schema = {
  email: VALIDATION_SCHEMA_EMAIL,
  code: {
    presence: { allowEmpty: false, message: '^Verifizierungscode erforderlich' },
    format: {
      pattern: /[0-9]{6}/,
      message: '^Der Code besteht aus 6 Zahlen',
    },
  },
  password: VALIDATION_SCHEMA_PASSWORD,
  confirm: {
    presence: { allowEmpty: false, message: '^Bitte Passwort wiederholen' },
    equality: {
      attribute: 'password',
      message: '^Passwörter stimmen nicht überein',
    },
  },
}

const useStyles = makeStyles((theme) => ({
  root: {},
  input: {
    marginTop: theme.spacing(2),
  },
  error: {
    marginTop: theme.spacing(1),
  }
}))

const ResetPasswordForm = ({ className, onSubmit, ...rest }) => {

  const classes = useStyles()

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}>

      <Formik
        initialValues={{
          email: '',
          code: '',
          password: '',
          confirm: '',
        }}
        validate={(values) => {
          return validate(values, schema, { format: 'flat' })
        }}
        onSubmit={(values, actions) => {
          onSubmit(values)
          actions.setSubmitting(false)
        }}>
        {({ isSubmitting, isValid, dirty, errors }) => (
          <Form>
            <Field
              className={classes.input}
              fullWidth
              label="Email"
              name="email"
              type="email"
              variant="outlined"
              component={TextField}
            />
            <Field
              className={classes.input}
              fullWidth
              label="Verifizierungscode"
              name="code"
              type="text"
              variant="outlined"
              component={TextField}
            />
            <Field
              className={classes.input}
              fullWidth
              label="Passwort"
              name="password"
              type="password"
              variant="outlined"
              component={TextField}
            />
            <Field
              className={classes.input}
              fullWidth
              label="Passwort wiederholen"
              name="confirm"
              type="password"
              variant="outlined"
              component={TextField}
            />
            <Typography color="error" className={classes.error}>{errors[0]}</Typography>
            <SubmitFab show={dirty} disabled={isSubmitting || !isValid}/>
          </Form>
        )}
      </Formik>

    </div>
  )
}

ResetPasswordForm.propTypes = {
  className: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
}

export default ResetPasswordForm

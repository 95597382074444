import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card'
import { CardActionArea } from '@material-ui/core'
import CardMedia from '@material-ui/core/CardMedia'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import RaceChart from './charts/RaceChart'

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 345,
    height: '100%',
  },
  actionArea: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  graph: {
    height: '100%',
  },
  header: {
    margin: theme.spacing(2)
  },
  displayArea: {
    width: '100%',
  },
}));

const LayoutCard = ({
  name,
  chartData,
}) => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardActionArea className={classes.graph}>
        {chartData && chartData.data.length > 0 && (
          <CardMedia className={classes.graph}>
            <div className={classes.actionArea}>
              <Typography variant="h3" className={classes.header}>
                {name}
              </Typography>
              <RaceChart
                className={classes.graph}
                barData={chartData}
            />
            </div>
          </CardMedia>
        )}
        {!chartData && (
          <CardMedia
            className={classes.displayArea}
            component="img"
            alt="placeholder"
            height="300"
            image="/static/images/placeholder.png"
            title="placeholder"/>
        )}
      </CardActionArea>
    </Card>
  );
}

LayoutCard.propTypes = {
  name: PropTypes.string,
  chartData: PropTypes.any,
}

export default LayoutCard

import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import SaveIcon from '@material-ui/icons/Save'
import Fab from '@material-ui/core/Fab'
import Zoom from '@material-ui/core/Zoom'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    bottom: theme.spacing(5),
    right: theme.spacing(5),
  },
  saveText: {
    display: 'flex',
    alignItems: 'center'
  },
  text: {
    paddingLeft: theme.spacing(1)
  }
}))

// Floating Action Button for submitting forms
const SubmitFab = ({ className, show, text, children, ...rest }) => {
  const classes = useStyles()

  return (
    <Zoom in={show}>
      <Fab
        color="primary"
        {...rest}
        className={clsx(classes.root, className)}
        variant="extended"
        type="submit">
        {children ? (
          <div className={classes.saveText}>
            {children}
            <Typography className={classes.text}>
              {text}
            </Typography>
          </div>
        ) : (
          <div className={classes.saveText}>
            <SaveIcon/>
            <Typography className={classes.text}>
              Speichern
            </Typography>
          </div>
        )}
      </Fab>
    </Zoom>
  )
}

SubmitFab.propTypes = {
  className: PropTypes.string,
  show: PropTypes.bool.isRequired,
  text: PropTypes.string,
  children: PropTypes.element,
}

export default SubmitFab

import React from 'react'
import { Container, Grid, makeStyles } from '@material-ui/core'
import Page from 'src/components/Page'
import { Route, Routes } from 'react-router-dom'
import AuthGuard from '../../../components/AuthGuard'
import DashboardList from './DashboardPage'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}))

const Dashboard = () => {
  const classes = useStyles()

  return (
    <Page
      className={classes.root}
      title="Betriebsstätten">
      <AuthGuard requiredRole={['admin', 'consultant', 'client']}>
        <Container maxWidth={false}>
          <Grid
            container>
            <Grid item xs={12}>
              <Routes>
                <Route name="dashboard" path="/" element={<DashboardList/>}/>
                <Route path="/?page=:page" element={<DashboardList/>}/>
              </Routes>
            </Grid>
          </Grid>
        </Container>
      </AuthGuard>
    </Page>
  )
}

export default Dashboard

import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import Amplify from 'aws-amplify'
import * as serviceWorker from './serviceWorker'
import App from './App'
import awsconfig from './aws-exports'

Amplify.configure(awsconfig)

ReactDOM.render((
  <BrowserRouter>
    <App/>
  </BrowserRouter>
), document.getElementById('root'))

serviceWorker.unregister()

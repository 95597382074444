import 'react-perfect-scrollbar/dist/css/styles.css'
import React from 'react'
import { useRoutes, useNavigate } from 'react-router-dom'
import { ThemeProvider } from '@material-ui/core'
import GlobalStyles from 'src/components/GlobalStyles'
import 'src/mixins/chartjs'
import routes from 'src/routes'
import { SnackbarProvider } from 'notistack'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { AuthProvider } from './auth'
import theme from './theme'

const useStyles = makeStyles(() => ({
  success: {
    backgroundColor: '#1A6E58 !important'
  },
  error: {
    backgroundColor: '#BF2015 !important'
  },
}))

const App = () => {
  const routing = useRoutes(routes)
  const navigation = useNavigate()
  const classes = useStyles()

  return (
    <SnackbarProvider
      maxSnack={3}
      classes={{
        variantSuccess: classes.success
      }}>
      <ThemeProvider theme={theme}>
        <AuthProvider navigate={navigation}>
          <GlobalStyles/>
          {routing}
        </AuthProvider>
      </ThemeProvider>
    </SnackbarProvider>
  )
}

export default App

const schema = () => {
  return {
    definitions: {},
    $schema: 'http://json-schema.org/draft-07/schema#',
    $id: 'https://example.com/object1614778781.json',
    title: 'Nutzereinstellungen',
    type: 'object',
    required: [
    ],
    properties: {
      siteLabelField: {
        $id: '#root/siteLabelField',
        title: 'Anzeigename für Betriebsstätten',
        type: 'string',
        enum: ['name', 'shortName'],
        enumNames: ['Name', 'Kürzel'],
        default: 'name'
      },
    }
  }
}

export default schema
